import clsx from 'clsx'
import {FormikProps, getIn} from 'formik'
import {FC, useMemo} from 'react'
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'

type Props = {
  onChange?: any
  className?: string
  labelClassName?: string | null
  inputClassName?: string | null
  label?: string | null
  size?: 'sm' | 'lg' | null
  type?: 'password' | 'text' | 'number'
  placeholder?: string
  required?: boolean
  formik: FormikProps<any>
  name?: any
  bgSolid?: boolean
  min?: number
  max?: number
  isCurrency?: boolean
  isInteger?: boolean
  checkFormik?: boolean
  disabled?: boolean
  onFocus?: any
  hidden?: boolean
  children?: any
  maxlength?: number
  configAppend?: {
    name: string
    position: 'right' | 'left'
    classInput?: string
    classAppend?: string
    hasEvent?: boolean
    handleSubmit?: any
  }
}

const InputInternationalPhoneFormik: FC<Props> = ({
  onChange = undefined,
  className,
  labelClassName = '',
  inputClassName = '',
  label = null,
  size = '',
  type = 'text',
  placeholder = '',
  required = false,
  formik,
  name,
  bgSolid = false,
  min,
  max,
  isCurrency = false,
  isInteger,
  checkFormik = true,
  disabled = false,
  onFocus,
  hidden = false,
  children,
  maxlength,
  configAppend,
}) => {
  let fieldProps = formik.getFieldProps(name)
  let formikErrors = getIn(formik.errors, name)
  let formikTouched = getIn(formik.touched, name)

  const checkValidate = useMemo(() => {
    const isInvalid = formikTouched && formikErrors && checkFormik
    const isValid = formikTouched && !formikErrors && fieldProps.value && checkFormik
    return {isInvalid, isValid}
  }, [checkFormik, fieldProps.value, formikErrors, formikTouched])

  const handleClickAppend = () => {
    if (!configAppend?.hasEvent && checkValidate.isInvalid) return
    if (!formikErrors && fieldProps.value && configAppend?.handleSubmit) {
      configAppend?.handleSubmit()
    }
  }

  return (
    <>
      {label && (
        <label className={`form-label ${labelClassName} ${required ? 'required' : ''}`}>
          {label}
        </label>
      )}
      <div className={className}>
        <div className='input-custom'>
          <PhoneInput
            disabled={disabled}
            country='us'
            inputClass={clsx('form-control input-custom__input w-100', {
              [inputClassName ?? '']: !!inputClassName,
              [`form-control-${size}`]: size,
              'form-control-solid': bgSolid,
              'is-invalid': checkValidate.isInvalid,
              'is-valid': checkValidate.isValid,
              [configAppend?.classInput ?? '']: !!configAppend?.classInput,
            })}
            {...fieldProps}
            placeholder={placeholder}
            onFocus={onFocus}
            onChange={(value: any) => {
              if (!/[0-9]/.test(value) && isInteger) {
                value.preventDefault()
              }
              if (/[,]/.test(value) && isCurrency) {
                value.preventDefault()
              }
              let event = {target: {name: fieldProps.name, value: value}}
              formik.handleChange(event)
              if (onChange) {
                onChange(value)
              }
            }}
          />
          <span
            className={clsx(
              `input-custom__append input-custom__append--${configAppend?.position}`,
              {
                'cursor-pointer text-hover-primary':
                  configAppend?.hasEvent && checkValidate.isValid,
                'cursor-no-drop': configAppend?.hasEvent && checkValidate.isInvalid,
                [configAppend?.classAppend ?? '']: !!configAppend?.classAppend,
              }
            )}
            onClick={handleClickAppend}
          >
            {configAppend?.name}
          </span>
        </div>
        {children}
        {formikTouched && formikErrors && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger'>
              <span role='alert'>{formikErrors}</span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export {InputInternationalPhoneFormik}
