import React, {useState} from 'react'
import {FreeYearModal} from '../modals/FreeYearModal'
import {Tiers} from './Tiers'
import {ComparePlanFeatures} from './ComparePlanFeatures'

export function PricingTiers() {
  const BASE_DOMAIN = process.env.REACT_APP_DOMAIN
  const registrationURL = document.location.protocol + '//' + BASE_DOMAIN + '/auth/registration'
  const [showFreeYearModal, setShowFreeYearModal] = useState(false)

  const handleTierClick = (e: React.MouseEvent, openModal: boolean) => {
    if (openModal) {
      e.preventDefault()
      setShowFreeYearModal(true)
    }
  }

  return (
    <>
      <FreeYearModal
        url={registrationURL}
        show={showFreeYearModal}
        onClose={() => setShowFreeYearModal(false)}
      />

      <Tiers page='pricing' onClick={handleTierClick} showPopularFlag={false} />

      <ComparePlanFeatures page='pricing' onClick={handleTierClick} />
    </>
  )
}
