import {useCallback, useEffect, useRef} from 'react'
import {toAbsoluteUrl} from '../../../_gori/helpers'
import {PublicWrapper} from '../../modules/common/components/PublicWrapper'

export function ServiceTermsPage() {
  const sectionsRef = useRef<{[key: string]: HTMLElement | null}>({})

  const getHeight = useCallback(() => {
    const navbar = document.querySelector('.fixed-top') as HTMLElement
    const navbarHeight = navbar?.offsetHeight
    return navbarHeight || 0
  }, [])

  const scrollToSection = useCallback(
    (targetId: string) => {
      const navHeight = getHeight()
      const targetElement = sectionsRef.current[targetId]

      if (targetElement) {
        const targetPosition =
          targetElement.getBoundingClientRect().top + window.scrollY - navHeight

        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth',
        })
      }
    },
    [getHeight]
  )

  const setAnchors = useCallback(() => {
    const anchorLinks = document.querySelectorAll('a[href^="#"]')

    anchorLinks.forEach((link) => {
      link.addEventListener('click', (event: Event) => {
        event.preventDefault()
        const targetId = (link.getAttribute('href') as string).substring(1)
        scrollToSection(targetId)
      })
    })
  }, [scrollToSection])

  useEffect(() => {
    const handleScrollOnLoad = () => {
      if (window.location.hash) {
        const targetId = window.location.hash.substring(1)
        requestAnimationFrame(() => scrollToSection(targetId))
      }
    }

    handleScrollOnLoad()
    setAnchors()
  }, [scrollToSection, setAnchors])
  return (
    <PublicWrapper>
      {/* title */}
      <div className='mt-20'>
        <div className='container mt-20'>
          <div className='justify-content-end d-flex'>
            <img
              src={toAbsoluteUrl('/media/gori/policies/check.png')}
              className='w-90px me-20'
              alt=''
            />
          </div>
          <div className='justify-content-center d-flex'>
            <div className='text-center display-2 fw-bolder pb-12' style={{color: '#16BAA8'}}>
              Terms of Service
            </div>
          </div>
          <div className='justify-content-start d-flex'>
            <img
              src={toAbsoluteUrl('/media/gori/policies/truck.png')}
              className='w-200px mx-5'
              alt=''
            />
          </div>
        </div>
      </div>

      {/* policy */}
      <div className='bg-white py-20'>
        <div className='container mb-10'>
          <div className='mt-5'>
            <p>
              <em>Effective Date: 3/6/2025</em>
            </p>
          </div>

          <div className='mt-5 pb-6'>
            <p>
              Gori Company dba ShipBae (collectively, “Provider”, “we”, “our”, or “us”) welcomes
              you. We invite you to access and use our websites, ShipBae platform, API, services and
              products (collectively, our “Services”), subject to the following terms and conditions
              (the “Terms of Service”). By using the Services, you acknowledge that you have read,
              understood, and agree to be legally bound by these Terms of Service and the terms and
              conditions of our privacy policy (the “Privacy Policy”), which are hereby incorporated
              into these Terms of Service and made a part hereof by reference (collectively, the
              “Agreement”). If you do not agree to any of the terms in the agreement, you may not
              access or use the Services.
            </p>

            <p>
              If you accept or agree to the Agreement on behalf of a company or other legal entity,
              you represent and warrant that you have the authority to bind that company or other
              legal entity to the Agreement and, in such event, “you” and “your” will refer and
              apply to that company or other legal entity.
            </p>

            <p>
              We reserve the right, at our sole discretion, to modify, discontinue, or terminate the
              Services, or to modify the Agreement, at any time and without prior notice. If we
              modify the Agreement, we will post the modifications on the Terms of Service page. By
              continuing to access or use the Services after we have posted the modifications, you
              are indicating that you agree to be bound by the modified Agreement. If the modified
              Agreement is not acceptable to you, your only recourse is to cease using the Website.
            </p>
          </div>

          <div className='pb-6' ref={(el) => (sectionsRef.current['account-registration'] = el)}>
            <h3 className='pb-3'>Account Registration and Guarantee of Payment</h3>
            <p>
              You agree to provide your legal name, company, address and contact information when
              you register for your account, and have such information transmitted to the carriers.
              You agree to pay and be held liable for any Service Fees as per the{' '}
              <a href='#payment-and-fees'>Payment and Fees</a> section below.
            </p>
          </div>

          <div className='pb-6' ref={(el) => (sectionsRef.current['payment-and-fees'] = el)}>
            <h3 className='pb-3'>Payment of Fees</h3>
            <p>
              You agree to a recurring monthly subscription fee, if any, based on the Pricing plan
              you selected during registration for the Services (“Subscription Fee”). Your
              subscription will continue and renew automatically unless terminated as per the{' '}
              <a href='#account-termination'>Account Termination</a> section below. In addition, you
              are responsible for all variable and transactional costs associated with the use of
              the Services. This includes, but is not limited to, costs related to postage, carrier
              service fees, adjustments for dimensional weight, package insurance, transaction fees
              imposed directly by the Provider for shipping or other services (“Transaction Fees”),
              as well as any other specialized services and administrative or processing fees
              (“Additional Fees”). Together, the Subscription Fee, Transaction Fees and the
              Additional Fees are referred to as the “Service Fees”.
            </p>
            <p>
              You must keep a valid credit card payment method on file with us to pay for all
              incurred and recurring Service Fees with sufficient credit to cover all applicable
              Service Fees for each billing period. If your payment method is canceled or terminated
              for any reason, you must provide a new, valid payment method immediately. If you
              choose to terminate your account, you agree to pay all charges for the current billing
              period at the time of cancellation, whether or not the current billing period is only
              partially complete.
            </p>
            <p>
              All Service Fees are nonrefundable. We may change the Service Fees then in effect, or
              add new Service Fees, by giving you notice in advance. Continued use of the Services
              after any such change in Service Fees shall constitute your consent to the new fees.
            </p>
          </div>

          <div className='pb-6' ref={(el) => (sectionsRef.current['account-funding'] = el)}>
            <h3 className='pb-3'>Account Funding</h3>

            <p>
              You may use certain carriers provided by us, like USPS, DHL Express, USPS Presort,
              etc., where we provide a Shipping Account (Wallet) for you to fund and from which the
              cost to print shipping labels will be deducted.
            </p>
            <p>
              Your Shipping Account (Wallet) may be funded by bank transfer, credit card, PayPal and
              other payment methods that Provider may add in the future, subject to certain limits,
              and you agree to maintain at least one valid payment method on file at all times your
              account is active. You agree Provider may place limits on the payment method used
              and/or the amount that your Shipping Account (Wallet) can be funded. You also agree
              that if your Shipping Account (Wallet) balance reaches negative for any reason,
              Provider has the right to charge any valid payment method that you authorize for any
              outstanding Service Fees.
            </p>
            <p>
              Credit cards may carry fees imposed by the provider of the payment method. In those
              cases, Provider will credit to your Shipping Account (Wallet) the net amounts after
              fees. By providing your credit card details and agreeing to these Terms you authorize
              us to charge your credit card with any amounts due to us under these Terms.
            </p>
          </div>

          <div className='pb-6' ref={(el) => (sectionsRef.current['non-payment'] = el)}>
            <h3 className='pb-3'>Non-Payment</h3>
            <p>
              If timely payment is not received or cannot be charged to your payment method for any
              reason, Provider reserves the right to either suspend or terminate your access to the
              Services and your account(s), and terminate this Agreement. If Provider receives a
              cancellation notice from you, you will be obligated to pay the balance due on your
              account. You agree that Provider may charge such unpaid fees to your payment method or
              otherwise bill you for such unpaid fees.
            </p>
          </div>

          <div className='pb-6' ref={(el) => (sectionsRef.current['refunds'] = el)}>
            <h3 className='pb-3'>Refunds</h3>
            <p>
              <strong>Cheaper Rates.</strong> No refunds shall be applicable to transactions that
              could have been obtained at a less expensive rate, unless the transaction qualifies
              for a refund as provided for elsewhere in the Agreement.
            </p>
            <p>
              <strong>Unused Label Refund Requests.</strong> If an unused label refund request is
              properly initiated by you within the timeframe required pursuant to carrier
              requirements, your account is active and in good standing, and the relevant carrier
              approves the refund, Provider will credit your Shipping Account (Wallet) for the full
              value of the transaction to be refunded, as approved by the carrier. Refund processing
              times may vary.
            </p>
            <p>
              <strong>Unused Label Refund Request Rejections.</strong> You take the risk of the
              carrier rejecting an unused label refund request. We will not refund the transaction
              unless the relevant carrier approves the refund.
            </p>
            <p>
              <strong>Outstanding Shipping Account (Wallet) Balance.</strong> If you have an
              outstanding balance in any amount (including but not limited to for unpaid Service
              Fees) you may not receive any reimbursement credit until your account is brought
              current. In addition, Provider may, in its sole discretion, deduct such unpaid fees
              from any refund otherwise due.
            </p>
            <p>
              <strong>Shipping Account (Wallet) Balance.</strong> You may receive a refund on any
              remaining Shipping Account (Wallet) balance if you request the refund via email to{' '}
              <a className='fw-bold' href='mailto:support@goricompany.com'>
                support@goricompany.com
              </a>
              . Your account must be active and in good standing to request a refund.
            </p>
            <p>
              <strong>Parcels Returned to Sender.</strong> Any parcel that is returned to the sender
              will not be considered a valid unused label. If determined valid, at Provider’s sole
              discretion, Provider will credit an active customer’s account an amount equal to what
              was paid for the Services, minus any applicable refund fee. In addition, you agree to
              assign to Provider any and all rights to file any such claims with or against any
              carrier with respect to Services ordered or paid for while using the Services.
            </p>
          </div>

          <div className='pb-6' ref={(el) => (sectionsRef.current['contact-and-billing'] = el)}>
            <h3 className='pb-3'>Contact and Billing Information</h3>
            <p>
              It is your responsibility to keep valid contact and billing information on your
              account. Provider will send out payment issue notices regarding your account in
              accordance with the contact information you have provided upon registration. To avoid
              service interruption due to non-payment issues, please ensure all contact information
              is kept current under your ShipBae Account Profile.
            </p>
          </div>

          <div className='pb-6' ref={(el) => (sectionsRef.current['account-termination'] = el)}>
            <h3 className='pb-3'>Account Termination</h3>
            <p>
              Provider, in its sole discretion, may terminate your password, account or use of the
              Services, and remove and discard any Data within the Services if you fail to comply
              with this Agreement. You may terminate your subscription by emailing{' '}
              <a className='fw-bold' href='mailto:support@goricompany.com'>
                support@goricompany.com
              </a>{' '}
              and including your ShipBae Account Number which can be found in your ShipBae Account
              Profile. If you terminate your account at any time, you may be liable for any balance
              of your current subscription, depending on your plan type, and will not receive a
              refund of any part of the subscription rate if you terminate your account during a
              billing cycle or plan period. In addition, Provider may terminate a free account if
              you do not first log in within 90 days after registration. Upon termination of an
              account, your right to use such an account and the Services immediately ceases.
              Provider shall have no obligation to maintain any Data stored in your account or to
              forward any Data to you or any third-party.
            </p>
          </div>
        </div>
      </div>
    </PublicWrapper>
  )
}
