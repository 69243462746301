import {AxiosRequestConfig} from 'axios'
import RequestBase from '../../../api/RequestBase'

const updateLanguage = (userId, payload, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`/users/profile/general/${userId}`, payload, config)
}

const uploadFile = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post('/media', payload, config)
}

const uploadFileBase64 = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post('/media-base64', payload, config)
}

const jiraAttachTemporaryFile = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post('/jira-attach-temporary', payload, config)
}

const CommonService = {
  updateLanguage,
  uploadFile,
  uploadFileBase64,
  jiraAttachTemporaryFile,
}

export default CommonService
