import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {intersection} from 'lodash'
import {
  Button,
  SelectFormik,
  ValidationErrorModal,
  ConfirmAssignOrderModal,
} from '../../../../_gori/partials/widgets'
import {isEmpty} from 'lodash'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import OrderService from '../core/_requests'
import {useAuth} from '../../auth'
import {DEFAULT_PAGE, PAGE_SIZE_MAX} from '../../../../_gori/constants'
import UserService from '../../users/core/_requests'

type Props = {
  show: boolean
  handleClose: () => void
  orders?: any
  reloadTable: () => {}
}

const AssignUserModal: React.FC<Props> = ({show, handleClose, orders, reloadTable}) => {
  const intl = useIntl()
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)
  const [users, setUsers] = useState<any>()
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [dataConfirm, setShowConfirmAssignOrderModal] = useState<any>()
  const {currentAccount} = useAuth()

  const getUsers = useCallback(async () => {
    const config = {
      params: {
        page: DEFAULT_PAGE,
        page_size: PAGE_SIZE_MAX,
        status: 'active',
      },
    }
    try {
      if (!currentAccount) return
      const response = await UserService.getListUserGlobal(currentAccount.encrypt_key, config)
      setUsers(response.users)
    } catch (error) {
      console.error(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCloseModal = () => {
    handleClose()
    formik.resetForm()
  }

  const [validateSchema] = useState<any>({})
  const validationSchema = Yup.object().shape(validateSchema)

  useEffect(() => {
    getUsers()

    return () => {}
  }, [getUsers])

  const optionsUsers = useMemo(() => {
    return (
      (users &&
        users?.length > 0 &&
        Object.entries(users).map(([key, value]: [any, any]) => ({
          value: value?.id,
          label: value?.first_name + ' ' + value?.last_name + ' | ' + value?.email,
        }))) ||
      []
    )
  }, [users])

  const formik = useFormik({
    initialValues: {
      order_ids: [],
      user_id: null,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async () => {
      const orderItems = orders.map((item: any) => {
        return item.original.id
      })
      let payload = {
        user_id: formik.values.user_id,
        order_ids: orderItems,
      }
      setIsLoadingForm(true)
      try {
        setShowConfirmAssignOrderModal(false)
        const res = await OrderService.assign(payload, {cancelToken: newCancelToken()})
        if (res) {
          toast.success(intl.formatMessage({id: 'ASSIGN_ORDER_SUCCESSFULLY'}))
          handleCloseModal()
          reloadTable()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setIsLoadingForm(false)
      }
    },
  })

  const checkConfirm = () => {
    const userId: any = []
    const userListId: any = []
    orders.forEach((item) => {
      if (item.original.user_id) {
        userId.push(item?.original?.user_id)
      }
    })

    users.forEach((item) => {
      userListId.push(item?.id)
    })
    let compareUserId = intersection(userListId, userId)
    if (!isEmpty(userId) && userId.length > 0 && !isEmpty(compareUserId)) {
      setShowConfirmAssignOrderModal('true')
    } else {
      formik.handleSubmit()
    }
  }
  const submitFormCustom = () => {
    formik.handleSubmit()
  }
  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <ConfirmAssignOrderModal
        handleClose={() => {
          setShowConfirmAssignOrderModal(false)
        }}
        response={dataConfirm}
        submitFormCustom={submitFormCustom}
      />
      <Modal
        id='gori_modal_assign_order'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='h-auto'
        show={show}
        backdrop='static'
        onHide={() => {
          handleClose()
        }}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'ASSIGN_TO'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3 className={`fw-bolder fs-2`}>{intl.formatMessage({id: 'UPDATE_ASSIGNMENT'})}</h3>
            <SelectFormik
              className='w-120'
              labelClassName={'text-muted'}
              placeholder={intl.formatMessage({id: 'SELECT_USER'})}
              options={optionsUsers}
              formik={formik}
              name='user_id'
            />
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
                disabled={isLoadingForm}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SUBMIT'})}
                loadingText={intl.formatMessage({id: 'SUBMIT'})}
                event={checkConfirm}
                loading={isLoadingForm}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {AssignUserModal}
