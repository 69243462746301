import {AxiosRequestConfig} from 'axios'
import RequestBase from '../../../api/RequestBase'

const BASE_URL = `/auth`

function retoolLogin(payload: any, config?: AxiosRequestConfig | undefined) {
  return RequestBase.post('/retool/login', payload, config)
}

function login(payload: any, config?: AxiosRequestConfig | undefined) {
  return RequestBase.post(`/login`, payload, config)
}

function register(payload: any, config?: AxiosRequestConfig | undefined) {
  return RequestBase.post(`${BASE_URL}/register`, payload, config)
}

function active(payload: any, config?: AxiosRequestConfig | undefined) {
  return RequestBase.post(`${BASE_URL}/active`, payload, config)
}

function resetPassword(payload: any, config?: AxiosRequestConfig | undefined) {
  return RequestBase.post(`${BASE_URL}/reset-password`, payload, config)
}

function newPassword(payload: any, config?: AxiosRequestConfig | undefined) {
  return RequestBase.post(`${BASE_URL}/new-password?token=${payload.token}`, payload, config)
}

function activeUser(payload: any, config?: AxiosRequestConfig | undefined) {
  return RequestBase.post(`/users/active`, payload, config)
}

function getVersion(config?: AxiosRequestConfig | undefined) {
  return RequestBase.get(`/get-version`, config)
}

function resendVerificationEmail(payload: any, config?: AxiosRequestConfig | undefined) {
  return RequestBase.post(`${BASE_URL}/resend-verification-email`, payload, config)
}

function verifyToken(config?: AxiosRequestConfig | undefined) {
  return RequestBase.get(`/verify-token`, config)
}

function sendOTP(payload: any, config?: AxiosRequestConfig | undefined) {
  return RequestBase.post(`/sms/verification`, payload, config)
}

function verifyOTP(payload: any, config?: AxiosRequestConfig | undefined) {
  return RequestBase.post(`/sms/check-verification`, payload, config)
}

const AuthService = {
  retoolLogin,
  login,
  register,
  active,
  resetPassword,
  newPassword,
  activeUser,
  getVersion,
  resendVerificationEmail,
  verifyToken,
  sendOTP,
  verifyOTP,
}

export default AuthService
