import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import AuthService from '../core/_requests'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {ErrorModal} from '../../../../_gori/partials/widgets'
import {useFormik} from 'formik'
import {Button, InputTextFormik} from '../../../../_gori/partials/widgets'
import {StorageHelpers} from '../../../../_gori/helpers'
import * as Yup from 'yup'

type Props = {
  show: boolean
  handleClose: () => void
  phone: string
  updateVerifiedPhone: () => void
}

const VerificationPhoneModal: React.FC<Props> = ({
  show,
  handleClose,
  phone,
  updateVerifiedPhone,
}) => {
  const intl = useIntl()
  const {newCancelToken} = useCancelToken()
  const timeExpired = 600
  const [timeLeft, setTimeLeft] = useState<number>(timeExpired)
  const [verifiedPhone, setVerifiedPhone] = useState(false)
  const [messageErrors, setMessageErrors] = useState<any>()
  const [messageErrorsSecond, setmessageErrorsSecond] = useState<any>(false)
  const [messageOTPExpired, setMessageOTPExpired] = useState(false)
  const [messageResend, setMessageResend] = useState(false)
  const spanRef = useRef<HTMLSpanElement>(null)

  const sendSmsOTP = useCallback(
    async (isResend = false) => {
      const payload = {
        phone: phone,
      }
      const config = {cancelToken: newCancelToken()}
      await AuthService.sendOTP(payload, config)
        .then((response) => {
          if (response.message === 'PHONE_IN_WHITELIST') {
            setVerifiedPhone(true)
            updateVerifiedPhone()
            StorageHelpers.setItemLocalStorage('PHONE_VERIFIED', phone)
          } else if (response.message === 'MAX_SEND_ATTEMPTS') {
            setmessageErrorsSecond(intl.formatMessage({id: 'MAX_SEND_ATTEMPTS'}))
            setMessageResend(false)
          } else if (response.message === 'PHONE_NUMBER_INVALID') {
            setMessageErrors(intl.formatMessage({id: 'PHONE_NUMBER_INVALID'}))
            setMessageResend(false)
          } else if (isResend) {
            setMessageResend(true)
            setmessageErrorsSecond(false)
            if (spanRef.current?.textContent === '00:00') {
              setTimeLeft(timeExpired)
              setMessageOTPExpired(false)
            }
          }
        })
        .catch((error: any) => {
          setMessageErrors(error?.message)
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [intl, newCancelToken, phone, updateVerifiedPhone]
  )
  const handleCloseModal = () => {
    handleClose()
  }
  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }

  const validationSchema = Yup.object().shape({
    otp: Yup.array().of(
      Yup.string().matches(/^[0-9]$/, intl.formatMessage({id: 'OTP_MUST_BE_NUMBER'}))
    ),
  })

  const formik = useFormik({
    initialValues: {otp: ['', '', '', '', '', '']},
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      const filledCount = values.otp.filter((item) => item !== '').length
      if (filledCount === 0) {
        setmessageErrorsSecond(intl.formatMessage({id: 'OTP_REQUIRED'}))
        return
      } else if (filledCount < 6) {
        setmessageErrorsSecond(intl.formatMessage({id: 'OTP_6_DIGIT'}))
        return
      }

      setSubmitting(true)
      const config = {cancelToken: newCancelToken()}
      const payload = {
        code: values.otp.join(''),
        phone: phone,
      }
      await AuthService.verifyOTP(payload, config)
        .then((response) => {
          if (response?.status === 'approved') {
            setVerifiedPhone(true)
            updateVerifiedPhone()
            StorageHelpers.setItemLocalStorage('PHONE_VERIFIED', phone)
          } else if (response?.status === 'MAX_CHECK_ATTEMPTS') {
            setmessageErrorsSecond(intl.formatMessage({id: 'MAX_CHECK_ATTEMPTS'}))
            setMessageResend(false)
            formik.resetForm()
          } else {
            setmessageErrorsSecond(intl.formatMessage({id: 'VERIFY_FAILED'}))
            setMessageResend(false)
            formik.resetForm()
          }
        })
        .catch((error: any) => {
          if (spanRef.current?.textContent === '00:00') {
            setMessageOTPExpired(true)
          } else {
            setMessageErrors(error?.message)
          }
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const focusToNextOTP = (event: any) => {
    const inputName = event?.target?.name
    const match = inputName.match(/\[(\d+)]/)
    const index = match ? parseInt(match[1], 10) : null

    // @ts-ignore
    if (index === null || index >= 5) {
      return
    }

    // eslint-disable-next-line no-template-curly-in-string
    // @ts-ignore
    const otpInput = document.querySelector<HTMLInputElement>(`input[name="otp[${index + 1}]"]`)
    if (otpInput) {
      otpInput.focus()
    }
  }

  useEffect(() => {
    if (!verifiedPhone) {
      sendSmsOTP()
    }
  }, [sendSmsOTP, verifiedPhone])

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0))
    }, 1000)
    return () => clearInterval(timer)
  })

  useEffect(() => {
    if (timeLeft === 0) {
      setMessageOTPExpired(true)
      setMessageResend(false)
    }
  }, [timeLeft])

  return (
    <>
      {messageErrors && (
        <ErrorModal
          handleClose={() => {
            setMessageErrors(undefined)
          }}
          message={messageErrors}
        />
      )}

      <Modal
        id='gori_modal_phone_verify'
        tabIndex={-1}
        centered
        dialogClassName='mw-600px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'VERIFY_CODE'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            {!verifiedPhone ? (
              <div>
                <h2 className='text-xl font-semibold mb-4 text-center'>
                  {intl.formatMessage({id: 'VERIFY_CODE'})}
                </h2>
                <div className='justify-content-center d-flex'>
                  <p className='mb-4 me-2'>{intl.formatMessage({id: 'REMAINING'})}</p>
                  <span className='text-teal-500 text-primary' ref={spanRef}>
                    {formatTime(timeLeft)}
                  </span>
                </div>

                {messageOTPExpired && (
                  <div className='text-center text-danger'>
                    {intl.formatMessage({id: 'OTP_EXPIRED'})}
                  </div>
                )}

                {!messageOTPExpired && (
                  <div className='mt-4'>
                    <div className='d-flex w-50 m-auto'>
                      {Array.from({length: 6}).map((_, index) => (
                        <InputTextFormik
                          key={index}
                          type='text'
                          name={`otp[${index}]`}
                          maxlength={1}
                          formik={formik}
                          className='mx-1 otp'
                          onChange={(event: any) => focusToNextOTP(event)}
                        />
                      ))}
                    </div>
                  </div>
                )}

                {messageErrorsSecond && (
                  <div className='text-danger text-center mt-3'>{messageErrorsSecond}</div>
                )}

                <div className='mt-7'>
                  <p className='d-flex w-100 justify-content-center'>
                    <span className='me-2'>
                      {intl.formatMessage({id: 'MESSAGE_NOTI_RESEND_CODE'})}
                    </span>
                    <span
                      className='text-danger cursor-pointer'
                      onClick={() => {
                        formik.resetForm()
                        sendSmsOTP(true)
                      }}
                    >
                      {intl.formatMessage({id: 'RESEND_CODE'})}
                    </span>
                  </p>
                </div>

                {messageResend && (
                  <div className='text-primary text-center'>
                    {intl.formatMessage({id: 'MESSAGE_RESEND'})}
                  </div>
                )}
              </div>
            ) : (
              <div>
                <h2 className='text-center'>{intl.formatMessage({id: 'VERIFIED'})}</h2>
                <div className='d-flex justify-content-center'>
                  <svg
                    width='120'
                    height='120'
                    viewBox='0 0 120 120'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M101.367 58.4731C101.367 61.2015 97.4389 63.4956 96.9124 66.0684C96.3859 68.6412 99.1151 72.2112 98.1151 74.5754C97.1152 76.9396 92.6647 77.4353 91.2442 79.5379C89.8237 81.6405 91.0387 85.9357 89.2507 87.7237C87.4626 89.5117 83.1674 88.2968 81.0648 89.7173C78.9622 91.1378 78.4666 95.5883 76.1023 96.5882C73.7381 97.5882 70.1681 94.8591 67.5953 95.3855C65.0225 95.912 62.7284 99.8398 60 99.8398C57.2716 99.8398 54.9775 95.912 52.4047 95.3855C49.8319 94.859 46.2619 97.5882 43.8977 96.5882C41.5335 95.5883 41.0378 91.1378 38.9352 89.7173C36.8326 88.2968 32.5374 89.5117 30.7493 87.7237C28.9613 85.9357 30.1763 81.6405 28.7558 79.5379C27.3353 77.4353 22.8848 76.9397 21.8849 74.5754C20.8849 72.2112 23.6141 68.6412 23.0876 66.0684C22.5611 63.4956 18.6333 61.2015 18.6333 58.4731C18.6333 55.7446 22.5611 53.4505 23.0875 50.8777C23.614 48.3049 20.8849 44.7349 21.8848 42.3708C22.8848 40.0066 27.3352 39.5109 28.7557 37.4083C30.1762 35.3058 28.9613 31.0105 30.7493 29.2225C32.5373 27.4344 36.8326 28.6494 38.9351 27.2289C41.0377 25.8084 41.5334 21.358 43.8976 20.358C46.2618 19.3581 49.8318 22.0872 52.4046 21.5607C54.9774 21.0342 57.2715 17.1064 59.9999 17.1064C62.7284 17.1064 65.0225 21.0342 67.5953 21.5607C70.1681 22.0872 73.7381 19.3581 76.1023 20.358C78.4664 21.358 78.9621 25.8084 81.0647 27.2289C83.1674 28.6494 87.4625 27.4345 89.2506 29.2225C91.0387 31.0105 89.8236 35.3057 91.2441 37.4083C92.6646 39.5109 97.1151 40.0066 98.1151 42.3708C99.115 44.7349 96.3859 48.3049 96.9124 50.8777C97.4389 53.4505 101.367 55.7446 101.367 58.4731Z'
                      fill='#14B8A6'
                    />
                    <path
                      d='M87.128 43.0604L81.9047 37.8378C81.1403 37.0735 79.9011 37.0736 79.1368 37.8379L52.465 64.5107L40.8633 52.9091C40.099 52.1448 38.8598 52.1447 38.0954 52.909L32.8722 58.1316C32.1077 58.8959 32.1077 60.1353 32.8721 60.8997L51.081 79.1089C51.8454 79.8732 53.0846 79.8732 53.8489 79.1088L87.128 45.8285C87.8924 45.064 87.8924 43.8247 87.128 43.0604Z'
                      fill='white'
                    />
                  </svg>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CLOSE'})}
                loadingText={intl.formatMessage({id: 'CLOSE'})}
                event={handleCloseModal}
                disabled={formik.isSubmitting}
              />
              {!verifiedPhone && (
                <Button
                  className='btn btn-primary'
                  label={intl.formatMessage({id: 'VERIFY'})}
                  loadingText={intl.formatMessage({id: 'VERIFY'})}
                  disabled={formik.isSubmitting || messageOTPExpired}
                  event={formik.submitForm}
                  loading={formik.isSubmitting}
                />
              )}
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {VerificationPhoneModal}
