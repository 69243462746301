import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import {toast} from 'react-toastify'
import UserService from '../../../../users/core/_requests'
import {Button, ValidationErrorModal} from '../../../../../../_gori/partials/widgets'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useAuth} from '../../../../auth'
import {SpecialOfferModal} from '../../../../../../_gori/partials/widgets/modals/SpecialOfferModal'
import {Tiers} from '../../../../common/components/Tiers'

type Props = {
  show: boolean
  handleClose: () => void
}

const ChangePricingPlan: React.FC<Props> = ({show, handleClose}) => {
  const intl = useIntl()
  const {setCurrentUser} = useAuth()
  const [validationErrors, setValidationErrors] = useState<any>()
  const {newCancelToken} = useCancelToken()
  const [showSpecialOfferModal, setShowSpecialOfferModal] = useState<boolean>(false)
  const [currentTier, setCurrentTier] = useState<string>('standard')

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    onSubmit: async () => {
      await handleSubmit({hasPass: false})
    },
  })

  const handleSubmit = async ({hasPass}) => {
    if (!hasPass) {
      return setShowSpecialOfferModal(true)
    }

    try {
      const config = {cancelToken: newCancelToken()}
      // TODO
      // Logic to update tier

      setCurrentUser(await UserService.getUser(config))
      toast.success(intl.formatMessage({id: 'UPDATED_SUCCESSFULLY'}))
    } catch (error: any) {
      setValidationErrors(error?.response)
    }
  }

  const handleCloseModal = () => {
    handleClose()
    formik.resetForm()
  }

  const handleCloseSpecialOfferModal = () => {
    setShowSpecialOfferModal(false)
    handleSubmit({hasPass: true})
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      {showSpecialOfferModal && (
        <SpecialOfferModal
          handleClose={() => {
            handleCloseSpecialOfferModal()
          }}
        />
      )}

      <Modal
        id='gori_modal_change_plan'
        tabIndex={-1}
        centered
        dialogClassName='mw-1250px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'PRICING_AND_PLANS'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <Container>
              <div className='pricing-plans pricing-page-container'>
                <Tiers
                  page='profile'
                  currentTier={currentTier}
                  setCurrentTier={setCurrentTier}
                  showPopularFlag={true}
                />
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
                disabled={formik.isSubmitting}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SAVE'})}
                loadingText={intl.formatMessage({id: 'SAVE'})}
                disabled={formik.isSubmitting}
                event={formik.submitForm}
                loading={formik.isSubmitting}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {ChangePricingPlan}
