function BlurredText({text, revealed, setRevealed}) {
  const toggleReveal = () => {
    setRevealed(true)
  }

  return (
    <span
      onClick={toggleReveal}
      style={{
        cursor: revealed ? 'text' : 'pointer',
        userSelect: revealed ? 'text' : 'none',
        MozUserSelect: revealed ? 'text' : 'none', // Firefox
        WebkitUserSelect: revealed ? 'text' : 'none', // Safari
        msUserSelect: revealed ? 'text' : 'none', // Internet Explorer/Edge
      }}
    >
      {revealed ? text : <span style={{filter: 'blur(5px)'}}>{text}</span>}
    </span>
  )
}

export default BlurredText
