export const ShipmentRefund = {
  SUBMITTED: 'submitted',
  PENDING: 'pending',
  PENDING_DISPUTED: 'pending_disputed',
  PENDING_EXCEPTIONS: 'pending_exceptions',
  PENDING_SERVER_DOWN: 'pending_server_down',
  REFUNDED: 'refunded',
  REJECTED: 'rejected',
}

export const ShipmentRefundLabel = {
  [ShipmentRefund.SUBMITTED]: 'REFUND_PROCESSING',
  [ShipmentRefund.PENDING]: 'REFUND_PENDING',
  [ShipmentRefund.PENDING_DISPUTED]: 'REFUND_PENDING_DISPUTED',
  [ShipmentRefund.PENDING_EXCEPTIONS]: 'REFUND_PENDING_EXCEPTIONS',
  [ShipmentRefund.PENDING_SERVER_DOWN]: 'REFUND_PENDING_SERVER_DOWN',
  [ShipmentRefund.REFUNDED]: 'REFUNDED',
  [ShipmentRefund.REJECTED]: 'REFUND_REJECTED',
}
