import {useIntl} from 'react-intl'
import {FAQ_ACCORDION_ITEMS} from './core/_const'
import {Link} from 'react-router-dom'
import {PublicWrapper} from '../../modules/common/components/PublicWrapper'
import {PricingTiers} from '../../modules/common/components/PricingTiers'
import {StartFreeToday} from './components/StartFreeToday'

export function PricingPage() {
  const intl = useIntl()

  return (
    <>
      <PublicWrapper>
        <div className='pricing-page-container'>
          <section className='hero pb-26'>
            <h1
              className='raleway custom-h1 text-center text-primary darker-primary mb-4'
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({id: 'PRICING_H1'}),
              }}
            ></h1>
            <p
              className='poppins text-center text-gray-700 fw-bold'
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({id: 'PRICING_SUBTITLE'}),
              }}
            ></p>
          </section>

          <PricingTiers />

          <section className='pricing-faq container mt-25'>
            <h2 className='mb-12 text-left lh-sm'>{intl.formatMessage({id: 'PRICING_FAQ'})}</h2>

            <div className='accordion' id='faqAccordion'>
              {FAQ_ACCORDION_ITEMS.map((accordionItem, index) => {
                const headingId = `heading-${index}`
                const collapseId = `collapse-${index}`

                return (
                  <div key={index} className='accordion-item'>
                    <h4 className='accordion-header' id={headingId}>
                      <button
                        className='accordion-button collapsed roboto fs-3 lh-lg fw-boldest mb-0'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target={`#${collapseId}`}
                        aria-expanded='false'
                        aria-controls={collapseId}
                      >
                        {intl.formatMessage({id: accordionItem.title})}
                      </button>
                    </h4>
                    <div
                      id={collapseId}
                      className='accordion-collapse collapse'
                      aria-labelledby={headingId}
                      data-bs-parent='#faqAccordion'
                    >
                      <div className='accordion-body'>
                        {accordionItem.hasLink ? (
                          <p className='mb-0 fs-5'>
                            {intl.formatMessage({id: accordionItem.content})}
                            {` `}
                            <a href={intl.formatMessage({id: accordionItem.link})}>
                              {intl.formatMessage({id: accordionItem.linkLabel})}
                            </a>
                            {` `}
                            {intl.formatMessage({id: accordionItem.content2})}
                            {` `}
                            <Link to={intl.formatMessage({id: accordionItem.link2})}>
                              {intl.formatMessage({id: accordionItem.linkLabel2})}
                            </Link>
                            {` `}
                            {intl.formatMessage({id: accordionItem.content3})}
                          </p>
                        ) : (
                          <p className='mb-0 fs-5'>
                            {intl.formatMessage({id: accordionItem.content})}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </section>

          <StartFreeToday />
        </div>
      </PublicWrapper>
    </>
  )
}
