import {getIn} from 'formik'
import React, {FC} from 'react'

type LinkItem = {
  label: string
  onClick: () => void
}

type Props = {
  className?: string
  checkboxClassName?: string
  linkClassName?: string
  labelCheck?: string | null
  labelLink?: string | null
  formik: any
  name: any
  links?: LinkItem[]
}

const AcceptTermCheckbox: FC<Props> = ({
  className,
  checkboxClassName,
  linkClassName,
  labelCheck = null,
  labelLink = null,
  formik,
  name,
  links = [],
}) => {
  let fieldProps = formik.getFieldProps(name)
  let formikErrors = getIn(formik.errors, name)
  let formikTouched = getIn(formik.touched, name)

  return (
    <div
      className={`fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid ${className}`}
    >
      <div className='form-check form-check-custom form-check-solid form-check-inline'>
        <input
          className={'form-check-input shadow-none'}
          type='checkbox'
          checked={fieldProps.value}
          {...fieldProps}
        />
        <span className={`form-check-label fw-bold text-gray-700 fs-6 ${checkboxClassName}`}>
          {labelCheck}
          {links.map((link, index) => (
            <span
              key={index}
              className={`ms-1 link-primary ${linkClassName}`}
              role='link'
              onClick={link.onClick}
            >
              {link.label} {index < links.length - 1 && ' | '}
            </span>
          ))}
        </span>
      </div>
      {formikTouched && formikErrors && (
        <div className='fv-plugins-message-container mt-2'>
          <div className='fv-help-block text-danger'>
            <span role='alert'>{formikErrors}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export {AcceptTermCheckbox}
