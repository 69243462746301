import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../_gori/helpers'

export function HowItWorks() {
  const intl = useIntl()
  return (
    <section className='how-it-works overflow-x-hidden'>
      <div className='container'>
        <h2 className='mb-30 text-left lh-sm'>{intl.formatMessage({id: 'HOW_IT_WORKS'})}</h2>
        <div className='d-flex w-100 flex-column flex-md-row justify-content-between align-items-start'>
          <div className='step'>
            <img
              className=''
              src={toAbsoluteUrl('/media/gori/landing/import-icon.svg')}
              alt='import orders icon'
            />
            <p className='numbered'>{intl.formatMessage({id: 'HOW_IT_WORKS_STEPS_1'})}</p>
            <p className='description'>{intl.formatMessage({id: 'HOW_IT_WORKS_STEPS_1_DESC'})}</p>
          </div>
          <div className='step'>
            <img
              className=''
              src={toAbsoluteUrl('/media/gori/landing/manage-shipping-icon.svg')}
              alt='manage shipping icon'
            />
            <p className='numbered'>{intl.formatMessage({id: 'HOW_IT_WORKS_STEPS_2'})}</p>
            <p className='description'>{intl.formatMessage({id: 'HOW_IT_WORKS_STEPS_2_DESC'})}</p>
          </div>
          <div className='step'>
            <img
              className=''
              src={toAbsoluteUrl('/media/gori/landing/print-labels-icon.svg')}
              alt='print labels icon'
            />
            <p className='numbered'>{intl.formatMessage({id: 'HOW_IT_WORKS_STEPS_3'})}</p>
            <p className='description'>{intl.formatMessage({id: 'HOW_IT_WORKS_STEPS_3_DESC'})}</p>
          </div>
          <div className='step'>
            <img
              className=''
              src={toAbsoluteUrl('/media/gori/landing/track-icon.svg')}
              alt='track and update icon'
            />
            <p className='numbered'>{intl.formatMessage({id: 'HOW_IT_WORKS_STEPS_4'})}</p>
            <p className='description'>{intl.formatMessage({id: 'HOW_IT_WORKS_STEPS_4_DESC'})}</p>
          </div>
        </div>
      </div>
    </section>
  )
}
