import React, {useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {CSSTransition} from 'react-transition-group'
import {KTSVG} from '../../../../../../_gori/helpers'
import {useAuth} from '../../../../auth'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {toast} from 'react-toastify'
import BlurredText from '../../../../../../_gori/partials/widgets/mixed/BlurredText'

export function ApiKeys() {
  const intl = useIntl()
  const {currentUser} = useAuth()

  const keys = useMemo(
    () => ({
      V2_STAGING: [
        {
          label: intl.formatMessage({id: 'STAGING'}) + ' ' + intl.formatMessage({id: 'V2_API'}),
          value: 'https://api-staging.goricompany.com/v2',
          hideBtn: true,
        },
        {
          label: intl.formatMessage({id: 'STAGING'}) + ' ' + intl.formatMessage({id: 'CLIENT_ID'}),
          value: currentUser?.account_integrations?.gori_staging?.client_id,
        },
        {
          label:
            intl.formatMessage({id: 'STAGING'}) + ' ' + intl.formatMessage({id: 'CLIENT_SECRET'}),
          value: currentUser?.account_integrations?.gori_staging?.client_secret,
        },
      ],
      V2_PRODUCTION: [
        {
          label: intl.formatMessage({id: 'V2_API'}),
          value: 'https://api.goricompany.com/v2',
          hideBtn: true,
        },
        {
          label: intl.formatMessage({id: 'CLIENT_ID'}),
          value: currentUser?.account_integrations?.gori_production?.client_id,
        },
        {
          label: intl.formatMessage({id: 'CLIENT_SECRET'}),
          value: currentUser?.account_integrations?.gori_production?.client_secret,
        },
      ],
    }),
    [currentUser, intl]
  )

  const initialRevealedState = useMemo(() => {
    return Object.fromEntries(
      Object.keys(keys).flatMap((key) => keys[key].map((item) => [item.value, false]))
    )
  }, [keys])
  const [revealed, setRevealed] = useState<any>(initialRevealedState)

  const updateRevealed = (value, newValue) => {
    setRevealed((prevState) => ({
      ...prevState,
      [value]: newValue,
    }))
  }

  return (
    <>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='card h-100'>
          <div className='card-header card-header-stretch'>
            <div className='card-title'>
              <h3>{intl.formatMessage({id: 'AUTHENTICATION_TOKENS'})}</h3>
            </div>
          </div>
          <div className='card-body pt-0 d-flex flex-column p-0'>
            <div className='dataTables_wrapper dt-bootstrap4 no-footer d-flex flex-column flex-fill'>
              <div className='scroll-x flex-fill scroll-y-clip'>
                <table className='table align-middle table-row-bordered fs-6 gy-4 dataTable no-footer'>
                  {Object.entries(keys).map(([title, data]) => {
                    return (
                      <>
                        <thead className='border-gray-200 fs-6 fw-bold bg-light'>
                          <tr>
                            <th colSpan={3} className='py-1 ps-9 text-uppercase text-gray-700'>
                              {intl.formatMessage({id: title})}
                            </th>
                          </tr>
                        </thead>
                        <tbody className='fs-6 fw-bold text-gray-600'>
                          {data.map((key) => {
                            return (
                              <tr role='row' key={key.value}>
                                <td role='cell' className='py-3'>
                                  <div className='ps-9 w-250px text-truncate'>{key.label}</div>
                                </td>
                                <td role='cell' className='py-3'>
                                  <BlurredText
                                    text={key.value}
                                    revealed={
                                      key.hideBtn ? !revealed[key.value] : revealed[key.value]
                                    }
                                    setRevealed={(newValue) => {
                                      if (!key.hideBtn) {
                                        updateRevealed(key.value, newValue)
                                      }
                                    }}
                                  />
                                </td>
                                <td role='cell' className='py-3'>
                                  {!key.hideBtn && key.value && (
                                    <span
                                      className='btn btn-sm btn-icon position-absolute ms-n9'
                                      onClick={() =>
                                        updateRevealed(key.value, !revealed[key.value])
                                      }
                                    >
                                      {revealed[key.value] ? (
                                        <i className='bi bi-eye fs-2' />
                                      ) : (
                                        <i className='bi bi-eye-slash fs-2' />
                                      )}
                                    </span>
                                  )}
                                  <CopyToClipboard
                                    text={key.value}
                                    onCopy={() => toast.success(intl.formatMessage({id: 'COPIED'}))}
                                  >
                                    <div>
                                      <div className='btn btn-active-color-primary btn-icon btn-sm btn-outline-light'>
                                        <KTSVG
                                          path='/media/gori/account/copy.svg'
                                          className='svg-icon'
                                        />
                                      </div>
                                    </div>
                                  </CopyToClipboard>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </>
                    )
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  )
}
