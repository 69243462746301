export const SET_UP_MODAL_ALL = {
  CREATE_LABELS: {
    key: 'CREATE_LABELS',
    title: 'LABELS_CREATION',
    process: 'CREATED_LABEL_OUT_OF_TOTAL_LABELS',
    failed: 'FAILED_TO_CREATE_LABELS',
    countCallApi: 10,
    notice: {
      key: 'CREATE_LABELS',
      title: 'LABELS',
      success: 'CREATED_SUCCESSFULLY',
      failed: 'CREATED_FAILED',
      showPrintLabels: true,
    },
  },
  RETURN_LABELS: {
    key: 'RETURN_LABELS',
    title: 'RETURN_LABELS',
    process: 'RETURN_LABEL_OUT_OF_TOTAL_LABELS',
    failed: 'FAILED_TO_RETURN_LABELS',
    countCallApi: 10,
    notice: {
      key: 'RETURN_LABELS',
      title: 'RETURN_LABELS',
      success: 'RETURN_LABEL_SUCCESSFULLY',
      failed: 'RETURN_LABEL_FAILED',
      showPrintLabels: true,
    },
  },
  PACKING_SLIPS: {
    key: 'PACKING_SLIPS',
    title: 'PACKING_SLIPS',
    process: 'PACKING_SLIP_OUT_OF_TOTAL_LABELS',
    failed: 'FAILED_TO_PRINT_PACKING_SLIP',
    countCallApi: 10,
    notice: {
      key: 'PACKING_SLIPS',
      title: 'PACKING_SLIPS',
      success: 'PRINT_PACKING_SLIP_SUCCESSFULLY',
      failed: 'PRINT_PACKING_SLIP_FAILED',
      showPrintLabels: true,
    },
  },
  REFUND_REQUEST: {
    key: 'REQUEST_REFUND',
    title: 'REQUEST_REFUND',
    process: 'REQUEST_REFUND_OUT_OF_TOTAL_LABELS',
    failed: 'FAILED_TO_REQUEST_REFUNDS',
    countCallApi: 10,
    notice: {
      key: 'REQUEST_REFUND',
      title: 'REQUEST_REFUND',
      success: 'REQUEST_REFUND_SUCCESSFULLY',
      failed: 'REQUEST_REFUND_FAILED',
      showPrintLabels: false,
    },
  },
}

export const SET_UP_MODAL_NOTICE = {
  ESTIMATED: {
    key: 'ESTIMATE_SERVICE',
    title: 'ESTIMATE_SERVICE',
    success: 'SET_THE_ESTIMATED_SERVICE_SUCCESSFULLY',
    failed: 'SET_THE_ESTIMATED_SERVICE_FAILED',
    showPrintLabels: false,
  },
  CHEAPEST: {
    key: 'CHEAPEST_SERVICE',
    title: 'CHEAPEST_SERVICE',
    success: 'SET_THE_CHEAPEST_SERVICE_SUCCESSFULLY',
    failed: 'SET_THE_CHEAPEST_SERVICE_FAILED',
    showPrintLabels: false,
  },
}

export const DEFAULT_SETTINGS = {
  COUNTRY: 'US',
}

export const PRICE_TIERS = [
  {
    tier_code: 'free',
    tier_name: 'PRICING_TIER_NAME_1',
    negotiable: false,
    openModal: false,
    price: '0',
    isPopular: false,
    feature_header: '',
    feature_list: [
      'PRICING_TIER_1_FEATURE_LIST_ITEM_1',
      'PRICING_TIER_1_FEATURE_LIST_ITEM_2',
      'PRICING_TIER_1_FEATURE_LIST_ITEM_3',
      'PRICING_TIER_1_FEATURE_LIST_ITEM_4',
      'PRICING_TIER_1_FEATURE_LIST_ITEM_5',
      'PRICING_TIER_1_FEATURE_LIST_ITEM_6',
      'PRICING_TIER_1_FEATURE_LIST_ITEM_7',
    ],
  },
  {
    tier_code: 'standard',
    tier_name: 'PRICING_TIER_NAME_2',
    negotiable: false,
    openModal: true,
    price: '10',
    isPopular: true,
    feature_header: 'PRICING_TIER_2_FEATURE_LIST_HEADER',
    feature_list: [
      'PRICING_TIER_2_FEATURE_LIST_ITEM_1',
      'PRICING_TIER_2_FEATURE_LIST_ITEM_2',
      'PRICING_TIER_2_FEATURE_LIST_ITEM_3',
      'PRICING_TIER_2_FEATURE_LIST_ITEM_4',
      'PRICING_TIER_2_FEATURE_LIST_ITEM_5',
    ],
  },
  {
    tier_code: 'enterprise',
    tier_name: 'PRICING_TIER_NAME_3',
    negotiable: true,
    openModal: false,
    price: 'ENTERPRISE_PRICE_DESCRIPTION',
    isPopular: false,
    feature_header: 'PRICING_TIER_3_FEATURE_LIST_HEADER',
    feature_list: ['PRICING_TIER_3_FEATURE_LIST_ITEM_1', 'PRICING_TIER_3_FEATURE_LIST_ITEM_2'],
  },
]

export const PRICE_TIER_COMPARISON = [
  {
    name: 'PRICING_COMPARISON_FEATURE_1',
    tier_name: 'PRICING_TIER_NAME_1',
    lowest_tier_available: 1,
    unlimited: true,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_2',
    tier_name: 'PRICING_TIER_NAME_1',
    lowest_tier_available: 1,
    unlimited: true,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_3',
    tier_name: 'PRICING_TIER_NAME_1',
    lowest_tier_available: 1,
    unlimited: true,
    number_of_users: 2,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_4',
    tier_name: 'PRICING_TIER_NAME_1',
    lowest_tier_available: 1,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_5',
    tier_name: 'PRICING_TIER_NAME_1',
    lowest_tier_available: 1,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_6',
    tier_name: 'PRICING_TIER_NAME_1',
    lowest_tier_available: 1,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_7',
    tier_name: 'PRICING_TIER_NAME_1',
    lowest_tier_available: 1,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_8',
    tier_name: 'PRICING_TIER_NAME_1',
    lowest_tier_available: 1,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_9',
    tier_name: 'PRICING_TIER_NAME_1',
    lowest_tier_available: 1,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_10',
    tier_name: 'PRICING_TIER_NAME_1',
    lowest_tier_available: 1,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_11',
    tier_name: 'PRICING_TIER_NAME_1',
    lowest_tier_available: 1,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_12',
    tier_name: 'PRICING_TIER_NAME_1',
    lowest_tier_available: 1,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_13',
    tier_name: 'PRICING_TIER_NAME_1',
    lowest_tier_available: 1,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_14',
    tier_name: 'PRICING_TIER_NAME_1',
    lowest_tier_available: 1,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_15',
    tier_name: 'PRICING_TIER_NAME_1',
    lowest_tier_available: 1,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_16',
    tier_name: 'PRICING_TIER_NAME_1',
    lowest_tier_available: 1,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_17',
    tier_name: 'PRICING_TIER_NAME_1',
    lowest_tier_available: 1,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_18',
    tier_name: 'PRICING_TIER_NAME_1',
    lowest_tier_available: 1,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_19',
    tier_name: 'PRICING_TIER_NAME_1',
    lowest_tier_available: 1,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_20',
    tier_name: 'PRICING_TIER_NAME_1',
    lowest_tier_available: 1,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_21',
    tier_name: 'PRICING_TIER_NAME_1',
    lowest_tier_available: 1,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_22',
    tier_name: 'PRICING_TIER_NAME_2',
    lowest_tier_available: 2,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_23',
    tier_name: 'PRICING_TIER_NAME_2',
    lowest_tier_available: 2,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_24',
    tier_name: 'PRICING_TIER_NAME_2',
    lowest_tier_available: 2,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_25',
    tier_name: 'PRICING_TIER_NAME_3',
    lowest_tier_available: 3,
  },
  {
    name: 'PRICING_COMPARISON_FEATURE_26',
    tier_name: 'PRICING_TIER_NAME_3',
    lowest_tier_available: 3,
  },
]
