import clsx from 'clsx'
import {isEmpty, uniqueId} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {ShowAddress} from '../../../../_gori/components'
import {TABLE_KEY_COLUMN} from '../../../../_gori/constants'
import {
  convertUserTimeZone,
  getShipmentFees,
  setLoadingActionTable,
} from '../../../../_gori/helpers'
import {blockPopUp} from '../../../../_gori/helpers/BrowserHelpers'
import {useStatesGlobal} from '../../../../_gori/helpers/components/StatesGlobalProvider'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {TrackingDetailModal} from '../../orders'
import {
  Button,
  CarrierBadge,
  Table,
  TableBody,
  TableWrapper,
} from '../../../../_gori/partials/widgets'
import {ParcelBadge} from '../../../../_gori/partials/widgets/mixed/ParcelBadge'
import {useAuth} from '../../auth'
import {ShipmentRefund, ShipmentRefundLabel} from '../../shipments/core/_const'
import ShipmentService from '../../shipments/core/_requests'

type Props = {
  tableData: any
}

const ShipmentTable: React.FC<Props> = ({tableData}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {statesGlobal} = useStatesGlobal()
  const carriers = statesGlobal.carriers
  const {newCancelToken, isCancel} = useCancelToken()
  const [shipments, setShipments] = useState<any>(tableData)
  const [showModal, setShowModal] = useState<{
    trackingDetail: boolean
  }>({
    trackingDetail: false,
  })
  const [dataModal, setDataModal] = useState<any>({
    trackingDetail: {},
  })

  const handlePrintLabel = useCallback(
    async (shipment: any) => {
      setShipments(setLoadingActionTable(shipments, [shipment.id], true))
      const data = {
        shipment_ids: [shipment.gori_shipment_id],
        shipment_labels: null,
      }
      await ShipmentService.print(data, {cancelToken: newCancelToken()})
        .then((response) => {
          if (blockPopUp(response.label_url)) {
            toast.warning(
              intl.formatMessage({
                id: 'POP_UP_IS_BLOCKED',
              })
            )
          }
        })
        .catch((error: any) => {
          if (isCancel(error)) return
        })
        .finally(() => {
          setShipments(setLoadingActionTable(shipments, [shipment.id], false))
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCancel, newCancelToken, intl, shipments]
  )

  const columns = useMemo(() => {
    return [
      {
        Header: intl.formatMessage({id: 'DATE'}),
        headerClassName: 'min-w-100px text-center',
        cellClassName: 'text-center',
        Cell: ({row}: {row: any}) => {
          return convertUserTimeZone(
            row.original?.created_at,
            currentUser,
            process.env.REACT_APP_DATE_FORMAT
          )
        },
      },
      {
        Header: intl.formatMessage({id: 'SERVICE'}),
        headerClassName: 'min-w-100px text-center',
        cellClassName: 'text-center',
        Cell: ({row}: {row: any}) => {
          let service = carriers?.[row.original.carrier + '_' + row.original.service] ?? []
          let sum = 0
          if (row.original?.fees) {
            sum = getShipmentFees(row.original?.fees?.items)
          }

          return (
            <div>
              <CarrierBadge service={service} cost={sum} />
            </div>
          )
        },
      },
      {
        Header: intl.formatMessage({id: 'SHIP_TO'}),
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          return <ShowAddress data={row.original} name='to' />
        },
      },
      {
        id: 'out_for_delivery',
        Header: intl.formatMessage({id: 'OUT_FOR_DELIVERY'}),
        headerClassName: 'min-w-150px text-center',
        cellClassName: 'text-center',
        Cell: (data) => {
          const row = data.row
          const ShipmentTracking = row.original?.shipment_tracking ?? null
          const ShipmentTrackingStatus = row.original?.status_label ?? null
          return (
            <div>
              {ShipmentTrackingStatus ? (
                <div className='justify-content-center'>
                  <span className='fw-bolder'>{ShipmentTrackingStatus}</span>
                  <br />
                  {ShipmentTracking && (
                    <span
                      className='cursor-pointer text-decoration-underline text-muted mt-4'
                      onClick={() => {
                        setShowModal((prev) => ({...prev, trackingDetail: true}))
                        setDataModal((prev) => ({...prev, trackingDetail: ShipmentTracking}))
                      }}
                    >
                      {intl.formatMessage({id: 'SEE_DETAIL'})}
                      {'>'}
                    </span>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          )
        },
      },
      {
        Header: intl.formatMessage({id: 'SHIP_FROM'}),
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          return <ShowAddress data={row.original} name='from' />
        },
      },
      {
        Header: intl.formatMessage({id: 'PACKAGE_INFO'}),
        headerClassName: 'min-w-100px text-center',
        cellClassName: 'text-center',
        Cell: ({row}: {row: any}) => {
          return row.original.parcels.map((parcel: any) => (
            <ParcelBadge key={uniqueId('parcel')} className={'bg-white'} values={parcel} />
          ))
        },
      },
      {
        Header: intl.formatMessage({id: 'TRACKING_NUMBER'}),
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          let service = carriers[row.original.carrier + '_' + row.original.service] ?? []

          return (
            <span
              className='text-primary cursor-pointer'
              onClick={() => {
                if (blockPopUp(service?.visuals?.tracking_url + row.original.tracking_number)) {
                  toast.warning(
                    intl.formatMessage({
                      id: 'POP_UP_IS_BLOCKED',
                    })
                  )
                }
              }}
            >
              {row.original?.tracking_number}
            </span>
          )
        },
      },
      {
        id: TABLE_KEY_COLUMN.ACTIONS,
        Header: intl.formatMessage({id: 'ACTIONS'}),
        headerClassName: 'min-w-150px text-center',
        cellClassName: 'text-center',
        Cell: ({row}: {row: any}) => {
          return (
            <div
              className={`shipment-refund-badge-${row?.original?.refund?.status} px-4 py-2 rounded-pill`}
            >
              {!isEmpty(row.original.refund) ? (
                <span>
                  {intl.formatMessage({
                    id: ShipmentRefundLabel[row.original.refund?.status] ?? 'PRINT_LABEL',
                  })}
                </span>
              ) : (
                <div
                  className={clsx('btn-group', {
                    'cursor-no-drop': row.original.isLoading,
                  })}
                >
                  <Button
                    className={clsx('btn btn-sm btn-primary', {
                      'pe-none': row.original.isLoading,
                      'btn-light-danger': row.original.refund?.status === ShipmentRefund.SUBMITTED,
                      'btn-light-primary': row.original.refund?.status === ShipmentRefund.REFUNDED,
                      'btn-light-info': row.original.refund?.status === ShipmentRefund.REJECTED,
                    })}
                    event={
                      row.original.refund === null ? () => handlePrintLabel(row.original) : null
                    }
                  >
                    {intl.formatMessage({
                      id: ShipmentRefundLabel[row.original.refund?.status] ?? 'PRINT_LABEL',
                    })}
                  </Button>
                </div>
              )}
            </div>
          )
        },
      },
    ]
  }, [carriers, currentUser, handlePrintLabel, intl])

  return (
    <>
      {showModal.trackingDetail && (
        <TrackingDetailModal
          show={showModal.trackingDetail}
          handleClose={() => setShowModal((prev) => ({...prev, trackingDetail: false}))}
          data={dataModal.trackingDetail}
        />
      )}

      <div className='mt-8'>
        <TableWrapper className='bg-white rounded'>
          <TableBody>
            <div className='fs-3 fw-bolder my-6'>{intl.formatMessage({id: 'SHIPMENTS'})}</div>
            <Table
              columns={columns}
              data={shipments}
              theadClass='text-gray-600 fw-bold bg-light'
              tbodyClass='text-gray-600 fw-bold'
              usePagination={false}
              useCheckBox={false}
            />
          </TableBody>
        </TableWrapper>
      </div>
    </>
  )
}

export {ShipmentTable}
