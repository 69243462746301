import React from 'react'
import {PRICE_TIERS} from '../core/_const'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

type Props = {
  page: string
  currentTier?: string
  setCurrentTier?: any
  onClick?: any
  showPopularFlag?: boolean
}

const Tiers: React.FC<Props> = ({page, currentTier, setCurrentTier, onClick, showPopularFlag}) => {
  const intl = useIntl()
  const BASE_DOMAIN = process.env.REACT_APP_DOMAIN
  const registrationURL = document.location.protocol + '//' + BASE_DOMAIN + '/auth/registration'

  return (
    <section className='pricing-options-container container mb-18'>
      <div className='d-flex flex-column flex-lg-row flex-nowrap row-cols-1 row-cols-lg-3 text-center justify-content-evenly align-items-center align-items-lg-stretch gap-7 mw-100'>
        {PRICE_TIERS.map((tier) => (
          <div
            className='col item-tier flex-fill mw-500px'
            onClick={setCurrentTier ? () => setCurrentTier(tier.tier_code) : undefined}
            key={tier.tier_name}
          >
            <div
              className={clsx('card h-100 p-6', {
                'active border-primary': currentTier === tier.tier_code,
              })}
            >
              {/* Card header */}
              <div
                className={clsx('card-header justify-content-center align-items-center pb-4', {
                  active: currentTier === tier.tier_code,
                })}
              >
                <h4 className='roboto my-0'>
                  {intl.formatMessage({id: tier.tier_name})}
                  {showPopularFlag && tier.isPopular && (
                    <span className='bg-primary text-white rounded-1 px-5 py-2 ms-5'>
                      {intl.formatMessage({id: 'POPULAR'})}
                    </span>
                  )}
                </h4>
              </div>

              {/* Card body */}
              <div className='card-body text-start d-flex flex-column justify-content-start px-0'>
                {tier.negotiable ? (
                  <p
                    className={clsx('card-title pricing-card-title roboto fw-bolder text-center', {
                      'text-primary': currentTier === tier.tier_code,
                    })}
                  >
                    {intl.formatMessage({id: tier.price})}
                  </p>
                ) : (
                  <p
                    className={clsx('card-title pricing-card-title roboto fw-bolder text-center', {
                      'text-primary': currentTier === tier.tier_code,
                    })}
                  >
                    ${tier.price}
                    <small className='text-body-secondary'>/mo</small>
                  </p>
                )}

                <p className='roboto fs-5 fw-bold text-primary mt-8 mb-0 ms-3'>
                  {tier.feature_header && intl.formatMessage({id: tier.feature_header})}
                </p>

                <ul className='mx-auto w-100 mb-4 fw-bold'>
                  {tier.feature_list.map((feature, featureIndex) => (
                    <li key={featureIndex} className='mb-1 roboto fw-bold'>
                      {intl.formatMessage({id: feature})}
                    </li>
                  ))}
                </ul>
                {page === 'pricing' && (
                  <a
                    href={tier.negotiable ? 'mailto:sales@goricompany.com' : registrationURL}
                    className='btn btn-primary btn-darker pretendard fw-boldest fs-2 lh-lg rounded-pill mt-auto'
                    onClick={(e) => onClick(e, tier.openModal)}
                  >
                    {intl.formatMessage({
                      id: tier.negotiable ? 'LINK_TO_SALES_LABEL' : 'GET_STARTED',
                    })}
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export {Tiers}
