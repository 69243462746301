import clsx from 'clsx'
import {getIn, useFormik} from 'formik'
import {compact, find} from 'lodash'
import moment from 'moment'
import React, {useCallback, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  InputDate,
  InputTextFormik,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../../_gori/partials/widgets'
import {useA1BatchesProvider} from '../../../../../_gori/providers/A1BatchesProvider'
import {FromOrToModal} from '../../../orders'
import A1_CONSTANTS from '../../core/_const'
import A1BatchService from '../../core/_requests'
import {A1ServiceModal} from './A1ServiceModal'

type Props = {
  show: boolean
  handleClose: () => void
  handleSuccess: () => void
}

const JourneyInformationModal: React.FC<Props> = ({show, handleClose, handleSuccess}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const {orderYup, stringYup, numberYup, regexYup, dateYup, infoYup} = UseYupValidate()
  const {a1Batch} = useA1BatchesProvider()

  const [validationErrors, setValidationErrors] = useState<any>()
  const [loading, setLoading] = useState<{update: boolean}>({update: false})
  const [hasShow, setHasShow] = useState<{a1Service: boolean}>({a1Service: false})

  const [fromToModal, setFormToModal] = useState<{
    show: boolean
    name: 'from' | 'to' | 'pickup_address'
    data: any
    validate: any
  }>({
    show: false,
    name: 'pickup_address',
    data: {},
    validate: {},
  })

  const handleCloseFromToModal = () => {
    setFormToModal({
      show: false,
      name: 'pickup_address',
      data: {},
      validate: {},
    })
  }

  const handleSaveModal = (name, values) => {
    formik.setFieldValue(name, values)
    handleCloseFromToModal()
  }

  const handleCloseModal = () => {
    handleClose()
  }

  const validateAddress = useCallback(
    (name) => {
      const validate = {
        [`${name}_company`]: Yup.string().when([`${name}_first_name`], {
          is: (first_name) => !first_name || first_name.trim() === '',
          then: orderYup.companyFirstName('COMPANY'),
          otherwise: stringYup(255, 'COMPANY', false),
        }),
        [`${name}_first_name`]: Yup.string().when(`${name}_company`, {
          is: (company) => !company || company.trim() === '',
          then: orderYup.companyFirstName('FIRST_NAME'),
          otherwise: stringYup(255, 'FIRST_NAME', false),
        }),
        [`${name}_street1`]: stringYup(255, 'ADDRESS'),
        [`${name}_city`]: stringYup(255, 'CITY'),
        [`${name}_state`]: Yup.string().when(`${name}_country`, {
          is: (val) => val === 'US',
          then: regexYup.state(),
          otherwise: stringYup(255, 'STATE'),
        }),
        [`${name}_country`]: regexYup.country(),
        [`${name}_zip`]: Yup.string().when(`${name}_country`, {
          is: (val) => val === 'US',
          then: regexYup.zipCode(),
          otherwise: stringYup(255, 'POSTAL_CODE'),
        }),
        [`${name}_last_name`]: stringYup(255, 'LAST_NAME', false),
        [`${name}_street2`]: stringYup(255, 'ADDRESS_2', false),
        [`${name}_phone`]: infoYup.phone('PHONE', false),
        [`${name}_email`]: infoYup.email('EMAIL', false),
      }
      return validate
    },
    [orderYup, regexYup, stringYup, infoYup]
  )

  const initValidateSchema = useMemo(() => {
    return {
      from: stringYup(255, 'FROM'),
      to: stringYup(255, 'TO'),
      pickup_address: Yup.object().shape(validateAddress('pickup_address'), [
        ['pickup_address_first_name', 'pickup_address_company'],
      ]),
      estimated_pickup_at: dateYup('PICK_UP_DATE', moment().startOf('day').format()),
      services: stringYup(255, 'A1_SERVICE'),
      pallets: numberYup.journey_info({
        name: 'PALLETS',
        max: {value: 10000, label: '10,000'},
      }),
      parcels: numberYup.journey_info({
        name: 'PARCELS',
        max: {value: 10000, label: '10,000'},
      }),
      weight: numberYup.journey_info({
        name: 'KGS',
        integer: false,
        min: {value: 0, label: '0'},
        max: {value: 1000000, label: '1,000,000'},
        moreThan: true,
      }),
    }
  }, [dateYup, numberYup, stringYup, validateAddress])

  const initialValues = useMemo(() => {
    return {
      from: find(a1Batch?.flights, {type: 'origin'})?.airport || null,
      to: find(a1Batch?.flights, {type: 'destination'})?.airport || null,
      pickup_address: {
        pickup_address_company: a1Batch?.pickup_address?.company || null,
        pickup_address_first_name: a1Batch?.pickup_address?.first_name || null,
        pickup_address_last_name: a1Batch?.pickup_address?.last_name || null,
        pickup_address_street1: a1Batch?.pickup_address?.street1 || null,
        pickup_address_street2: a1Batch?.pickup_address?.street2 || null,
        pickup_address_city: a1Batch?.pickup_address?.city || null,
        pickup_address_state: a1Batch?.pickup_address?.state || null,
        pickup_address_zip: a1Batch?.pickup_address?.zip || null,
        pickup_address_country: a1Batch?.pickup_address?.country || null,
        pickup_address_phone: a1Batch?.pickup_address?.phone || null,
        pickup_address_email: a1Batch?.pickup_address?.email || null,
        pickup_address_is_residential: false,
      },
      estimated_pickup_at: a1Batch?.estimated_pickup_at || moment(),
      services: 'all_in_one',
      pallets: a1Batch?.origin?.packaging?.pallets || null,
      parcels: a1Batch?.origin?.packaging?.parcels || null,
      weight: parseFloat(a1Batch?.origin?.packaging?.weight?.value) || null,
    }
  }, [
    a1Batch?.flights,
    a1Batch?.pickup_address,
    a1Batch?.estimated_pickup_at,
    a1Batch?.origin?.packaging?.pallets,
    a1Batch?.origin?.packaging?.parcels,
    a1Batch?.origin?.packaging?.weight?.value,
  ])

  const formik = useFormik({
    initialValues,
    enableReinitialize: false,
    validationSchema: Yup.object().shape(initValidateSchema),
    onSubmit: async (values) => {
      const config = {cancelToken: newCancelToken()}
      try {
        setLoading((prev) => ({...prev, update: true}))
        const res = await A1BatchService.updateA1Batch(
          a1Batch.id,
          {
            ...values,
            pickup_address: Object?.entries(values?.pickup_address)?.reduce(
              (address, [key, value]) => {
                address[`${key?.split('pickup_address_')[1]}`] = value
                return address
              },
              {}
            ),
            epu_at: values?.estimated_pickup_at,
          },
          config
        )
        if (res) {
          handleSuccess()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, update: false}))
      }
    },
  })

  // BEGIN: Pickup Address
  const shipPickUpFromShow = useMemo(() => {
    const {pickup_address_city, pickup_address_country} = formik.values.pickup_address
    const addressTo = compact([pickup_address_city, pickup_address_country]).join(', ')
    return addressTo
  }, [formik.values.pickup_address])

  const checkValidateFailed = useMemo(() => {
    if (
      !formik.values.pickup_address.pickup_address_company &&
      !formik.values.pickup_address.pickup_address_first_name
    ) {
      return true
    }
    const validate = [
      'pickup_address_city',
      'pickup_address_state',
      'pickup_address_country',
      'pickup_address_zip',
      'pickup_address_street1',
    ]
    return validate.some((item) => !formik.values.pickup_address[item])
  }, [formik.values])
  // END: Pickup Address

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      {hasShow.a1Service && (
        <A1ServiceModal
          show={show}
          handleClose={() => {
            setHasShow((prev) => ({...prev, a1Service: false}))
          }}
        />
      )}
      {fromToModal.show && fromToModal.name === 'pickup_address' && (
        <FromOrToModal
          data={fromToModal}
          handleSave={handleSaveModal}
          handleClose={handleCloseFromToModal}
          labelModal={'PICK_UP_ADDRESS'}
        />
      )}
      <Modal
        id='gori_modal_journey_information_modal'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1250px h-auto'
        show={show && !fromToModal.show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'JOURNEY_INFORMATION'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={clsx('vh-75 scroll-y', {'cursor-no-drop': loading.update})}>
            <Container className={clsx({'pe-none opacity-75': loading.update})}>
              <div className='border-bottom'>
                <h3 className='required'> {intl.formatMessage({id: 'PICK_UP_INFORMATION'})}</h3>
                <div className='d-flex flex-column flex-md-row my-8 gap-6'>
                  <div className='col-12 col-md-4'>
                    <div className='d-flex'>
                      <SelectFormik
                        className='col-8'
                        labelClassName='col-4 col-form-label fs-5 fw-bolder text-dark'
                        label={intl.formatMessage({id: 'FROM'})}
                        placeholder={intl.formatMessage({id: 'ORIGIN'})}
                        options={A1_CONSTANTS.OPTIONS_ORIGIN}
                        formik={formik}
                        name='from'
                        emptyDefault={false}
                      />
                    </div>
                    <div className='d-flex mt-4'>
                      <SelectFormik
                        className='col-8'
                        labelClassName='col-4 col-form-label fs-5 fw-bolder text-dark'
                        label={intl.formatMessage({id: 'TO'})}
                        placeholder={intl.formatMessage({id: 'DESTINATION'})}
                        options={A1_CONSTANTS.OPTIONS_DESTINATION}
                        formik={formik}
                        name='to'
                        emptyDefault={false}
                      />
                    </div>
                  </div>
                  <div className='d-flex col-12 col-md-4 mt-4 mt-md-0'>
                    <SelectFormik
                      className='col-8'
                      labelClassName='col-4 col-form-label fs-5 fw-bolder text-dark'
                      label={intl.formatMessage({id: 'A1_SERVICE'})}
                      placeholder={intl.formatMessage({id: 'SELECT'})}
                      options={Object.values(A1_CONSTANTS.OPTIONS_A1_SERVICE)}
                      formik={formik}
                      name='services'
                      emptyDefault={false}
                      onChange={(selected) => {
                        if (selected.value === A1_CONSTANTS.OPTIONS_A1_SERVICE.ALL_IN_ONE.value) {
                          setHasShow((prev) => ({...prev, a1Service: true}))
                        }
                      }}
                      hasUseIntl={true}
                    />
                  </div>
                  <div className='col-12 col-md-4 mt-4 mt-md-0'>
                    <div className='d-flex'>
                      <InputDate
                        className={'col-8'}
                        label={intl.formatMessage({id: 'PICK_UP'})}
                        labelClassName='col-4 col-form-label fs-5 fw-bolder text-dark'
                        name='estimated_pickup_at'
                        formik={formik}
                        min={moment()}
                        views={['year', 'month', 'day', 'hours', 'minutes']}
                      />
                    </div>
                    <div className='d-flex flex-column mt-4'>
                      <div className='d-flex'>
                        <label className=' col-4 col-form-label fs-5 fw-bolder text-dark'>
                          {intl.formatMessage({id: 'PICK_UP_ADDRESS'})}
                        </label>
                        <div className='input-custom col-8'>
                          <input
                            value={shipPickUpFromShow}
                            className={clsx('form-control input-custom__input cursor-pointer ', {
                              'is-invalid':
                                getIn(formik.touched, 'pickup_address') && checkValidateFailed,
                              'is-valid':
                                getIn(formik.touched, 'pickup_address') && !checkValidateFailed,
                            })}
                            placeholder={intl.formatMessage({id: 'ADD_PICK_UP_ADDRESS'})}
                            onClick={() => {
                              formik.setFieldTouched('pickup_address', true)
                              setFormToModal({
                                show: true,
                                name: 'pickup_address',
                                data: formik.getFieldProps('pickup_address').value,
                                validate: validateAddress('pickup_address'),
                              })
                            }}
                            onChange={(e) => e.preventDefault()}
                          />
                        </div>
                      </div>
                      <div className='d-flex mt-1'>
                        <div className='col-4' />
                        {checkValidateFailed && getIn(formik.touched, 'pickup_address') && (
                          <span className='text-danger fs-7'>
                            {intl.formatMessage(
                              {id: 'INPUT_IS_REQUIRED'},
                              {input: intl.formatMessage({id: 'PICK_UP_ADDRESS'})}
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='border-bottom mt-10'>
                <h3>{intl.formatMessage({id: 'SHIPMENT_DETAIL'})}</h3>
                <div className='d-flex flex-column flex-md-row my-8 gap-6'>
                  <div className='d-flex col-12 col-md-4 mt-4 mt-md-0'>
                    <InputTextFormik
                      className='col-8'
                      label={`# ${intl.formatMessage({id: 'OF_PALLETS'})}`}
                      labelClassName='col-4 col-form-label fs-5 fw-bolder text-dark'
                      formik={formik}
                      type='number'
                      isInteger
                      name='pallets'
                    />
                  </div>
                  <div className='d-flex col-12 col-md-4 mt-4 mt-md-0'>
                    <InputTextFormik
                      className='col-8'
                      label={`# ${intl.formatMessage({id: 'OF_PARCELS'})}`}
                      labelClassName='col-4 col-form-label fs-5 fw-bolder text-dark'
                      formik={formik}
                      type='number'
                      isInteger
                      name='parcels'
                    />
                  </div>
                  <div className='d-flex col-12 col-md-4 mt-4 mt-md-0'>
                    <InputTextFormik
                      className='col-8'
                      label={intl.formatMessage({id: 'KGS'})}
                      labelClassName='col-4 col-form-label fs-5 fw-bolder text-dark'
                      formik={formik}
                      type='number'
                      name='weight'
                    />
                  </div>
                </div>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'UPDATE'})}
                loadingText={intl.formatMessage({id: 'UPDATE'})}
                disabled={loading.update}
                event={formik.handleSubmit}
                loading={loading.update}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {JourneyInformationModal}
