import React, {ReactNode} from 'react'
import {CSSTransition} from 'react-transition-group'
import {Header} from './Header'
import {Footer} from './Footer'

type WrapperProps = {
  children: ReactNode
}

export const PublicWrapper: React.FC<WrapperProps> = ({children}) => {
  return (
    <>
      <Header />
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <main style={{paddingTop: '80px'}}>{children}</main>
      </CSSTransition>

      <Footer />
    </>
  )
}
