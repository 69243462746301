import clsx from 'clsx'
import {useFormik} from 'formik'
import {isEmpty} from 'lodash'
import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {FEATURES} from '../../../../_gori/constants/_features'
import {allowTestOnly, isFeatureEnabled, toAbsoluteUrl} from '../../../../_gori/helpers'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../_gori/hooks/UseYupValidate'
import {Button} from '../../../../_gori/partials/widgets'
import {InputCheckboxFormik} from '../../../../_gori/partials/widgets/form/InputCheckboxFormik'
import AgreementService from '../../agreements/core/_requests'
import {useAuth} from '../../auth'

type Props = {
  show: boolean
  handleClose: () => void
}

const HazmatModal: React.FC<Props> = ({show, handleClose}) => {
  const intl = useIntl()
  const {authYup} = UseYupValidate()
  const {currentUser} = useAuth()
  const {isCancel, newCancelToken} = useCancelToken()
  const [loading, setLoading] = useState(false)
  const accepted = !!currentUser?.remind_at

  const validationSchema = Yup.object().shape({
    accept_terms: authYup.acceptTerms,
  })

  const formik = useFormik({
    initialValues: {
      accept_terms: accepted,
      show_reminder: false,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (accepted && !values.show_reminder) {
        handleClose()
      }

      const payload = {
        hazmat: values.accept_terms,
        show_reminder: false,
      }

      const config = {
        cancelToken: newCancelToken(),
      }

      try {
        setLoading(true)
        const res = await AgreementService.updateHazmat(payload, config)

        if (res) {
          handleCloseModal()
        }
      } catch (error) {
        if (isCancel(error)) return
      } finally {
        setLoading(false)
      }
    },
  })

  const handleCloseModal = () => {
    formik.handleSubmit()
    handleClose()
  }

  return (
    <>
      <Modal
        className='hazmat'
        id='gori_modal_hazmat'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1100px h-auto'
        show={show}
        backdrop='static'
        onHide={() => {
          handleCloseModal()
        }}
        keyboard={accepted}
      >
        <div className='ps-5 modal-content'>
          <Modal.Header closeButton={allowTestOnly(currentUser) ? true : accepted}>
            <Modal.Title bsPrefix={'hazmat__header'}>
              {intl.formatMessage({
                id: accepted
                  ? 'NOTICE'
                  : 'REQUIRED_CONSENT_FOR_HAZMAT_HAZARDOUS_MATERIAL_SHIPPING_POLICY',
              })}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <p
              className='hazmat__content mb-9'
              dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'EFFECTIVE_OCTOBER_1'})}}
            ></p>
            <p
              className='hazmat__blue m-0'
              dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'IN_ACCORDANCE'})}}
            ></p>
            <p
              className='hazmat__blue mb-9'
              dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'TO_LEARN'})}}
            ></p>
            <ol className='m-0'>
              <li className='hazmat__list'>
                {intl.formatMessage({id: 'HAZMAT_REFER_TO_FOLLOWING_DOCUMENTS_CONTENT_1'})}{' '}
                <a
                  href='https://pe.usps.com/text/pub52/welcome.htm'
                  target='_blank'
                  rel='noreferrer'
                >
                  {intl.formatMessage({id: 'USPS_PUBLICATION_52'})}
                </a>
              </li>
              <li className='hazmat__list'>
                {intl.formatMessage({id: 'HAZMAT_REFER_TO_FOLLOWING_DOCUMENTS_CONTENT_2'})}{' '}
                <a href='https://pe.usps.com/hazmat/index' target='_blank' rel='noreferrer'>
                  {intl.formatMessage({id: 'HAZMAT_ITEMS_SEARCH_TOOL'})}
                </a>
              </li>
            </ol>

            <hr className='hazmat__hr my-6' />

            <p className='hazmat__blue mb-4'> {intl.formatMessage({id: 'BY_PROCEEDING'})}</p>
            <p
              className='hazmat__content m-0'
              dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'HAZMAT_DESCRIPTION'})}}
            ></p>
            <div className='hazmat__img m-0'>
              <img src={toAbsoluteUrl('/media/gori/dashboard/hazmat/battery.png')} alt='' />
              <img src={toAbsoluteUrl('/media/gori/dashboard/hazmat/thermometer.png')} alt='' />
              <img src={toAbsoluteUrl('/media/gori/dashboard/hazmat/spray.png')} alt='' />
              <img src={toAbsoluteUrl('/media/gori/dashboard/hazmat/liquor.png')} alt='' />
              <img src={toAbsoluteUrl('/media/gori/dashboard/hazmat/nail.png')} alt='' />
              <img src={toAbsoluteUrl('/media/gori/dashboard/hazmat/paint.png')} alt='' />
              <img src={toAbsoluteUrl('/media/gori/dashboard/hazmat/fuel.png')} alt='' />
            </div>
            <p className='hazmat__content mb-1'>
              {intl.formatMessage({id: 'HAZMAT_CATEGORY_INCLUDE'})}
            </p>
            <a
              href='https://gori.ai/faq/?q=hazmat'
              target='_blank'
              rel='noreferrer'
              className={clsx('hazmat__link mb-0', {'pe-none': !isFeatureEnabled(FEATURES.HAZMAT)})}
            >
              {intl.formatMessage({id: 'LEARN_MORE'})}
            </a>
            <p className='hazmat__blue mt-6 mb-4'>
              {intl.formatMessage({id: 'SENDING_DANGEROUS'})}
            </p>

            <ul className='hazmat__list mb-6'>
              <li>
                <strong className='d-inline-block w-25'>
                  {intl.formatMessage({id: 'CONSEQUENCES_LIST_1_ITEM_1'})}
                </strong>
                <span>{intl.formatMessage({id: 'CONSEQUENCES_LIST_2_ITEM_1'})}</span>
              </li>
              <li>
                <strong className='d-inline-block w-25'>
                  {intl.formatMessage({id: 'CONSEQUENCES_LIST_1_ITEM_2'})}
                </strong>
                <span>{intl.formatMessage({id: 'CONSEQUENCES_LIST_2_ITEM_2'})}</span>
              </li>
              <li>
                <strong className='d-inline-block w-25'>
                  {intl.formatMessage({id: 'CONSEQUENCES_LIST_1_ITEM_3'})}
                </strong>
                <span>{intl.formatMessage({id: 'CONSEQUENCES_LIST_2_ITEM_3'})}</span>
              </li>
              <li>
                <strong className='d-inline-block w-25'>
                  {intl.formatMessage({id: 'CONSEQUENCES_LIST_1_ITEM_4'})}
                </strong>
                <span>{intl.formatMessage({id: 'CONSEQUENCES_LIST_2_ITEM_4'})}</span>
              </li>
            </ul>

            <p
              className='hazmat__blue'
              dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'NOTE_PIN'})}}
            ></p>

            <ul>
              <li>
                <em>{intl.formatMessage({id: 'DOCUMENTS_LIST_1_ITEM_1'})}</em>
              </li>
              <li>
                <em>{intl.formatMessage({id: 'DOCUMENTS_LIST_1_ITEM_2'})}</em>
              </li>
              <li>
                <em>{intl.formatMessage({id: 'DOCUMENTS_LIST_1_ITEM_3'})}</em>
              </li>
            </ul>

            <hr className='hazmat__hr my-6' />

            <div className='hazmat__content mb-5'>{intl.formatMessage({id: 'HAZMAT_THANKS'})}</div>
            <div className='hazmat__content  mb-5'>
              {intl.formatMessage({id: 'THANK_YOU_FOR_YOUR_ATTENTION_TO_THIS_IMPORTANT_MATTER'})}
            </div>
            <div className='hazmat__content mb-1'>{intl.formatMessage({id: 'SINCERELY'})},</div>
            <img className='h-25px' src='/media/gori/hazmat/gori_company.png' alt='gori-company' />
            {!accepted && (
              <div className='mt-6 hazmat__content hazmat__content--medium'>
                <div>
                  <span className='required text-content fs-5'>
                    {intl.formatMessage({id: 'ACCEPT_TERMS'})}
                  </span>
                  {formik.errors.accept_terms && formik.touched.accept_terms && (
                    <span className='text-danger'>
                      {intl.formatMessage({id: 'YOU_MUST_ACCEPT_THE_TERMS_AND_CONDITIONS'})}
                    </span>
                  )}
                </div>
                <div className='d-flex'>
                  <div className='mt-3 me-3 form-check form-check-sm'>
                    <input
                      type='checkbox'
                      className='form-check-input cursor-pointer'
                      {...formik.getFieldProps('accept_terms')}
                    />
                  </div>
                  <div className='text-content'>
                    {intl.formatMessage({id: 'ACCEPT_TERMS_DESCRIPTION'})}
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div
              className={clsx('col-12 align-items-center', {
                'cursor-no-drop': !accepted && !isEmpty(formik.errors),
              })}
            >
              {accepted && (
                <InputCheckboxFormik
                  formik={formik}
                  className={'float-start mt-3'}
                  labelClassName={'fs-6 fw-semibold text-gray-700'}
                  type='checkbox'
                  name='show_reminder'
                  label={intl.formatMessage({id: 'HIDE_48_HOURS'})}
                />
              )}
              <Button
                className={clsx('float-end btn btn-primary cursor-pointer')}
                loading={loading}
                label={intl.formatMessage({id: accepted ? 'CLOSE' : 'SUBMIT'})}
                loadingText={intl.formatMessage({id: accepted ? 'CLOSE' : 'SUBMIT'})}
                event={() => formik.handleSubmit()}
                disabled={!accepted && !isEmpty(formik.errors)}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {HazmatModal}
