import {toAbsoluteUrl} from '../../../../_gori/helpers'

export function Discounts() {
  return (
    <section className='discounts'>
      <div className='container'>
        <div className='d-flex flex-row flex-wrap justify-content-center gap-9 align-items-center mx-auto mb-22'>
          <img
            className=''
            src={toAbsoluteUrl('/media/gori/landing/usps-discount.svg')}
            alt='usps'
          />
          <img
            className=''
            src={toAbsoluteUrl('/media/gori/landing/fedex-discount.svg')}
            alt='fedex'
          />
          <img className='' src={toAbsoluteUrl('/media/gori/landing/ups-discount.svg')} alt='ups' />
          <img className='' src={toAbsoluteUrl('/media/gori/landing/dhl-discount.svg')} alt='dhl' />
        </div>
      </div>
    </section>
  )
}
