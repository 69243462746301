import React, {useEffect, useMemo, useState} from 'react'
import {Welcome} from './Welcome'
import {toAbsoluteUrl} from '../../../../_gori/helpers'
import OnboardService from '../core/_requests'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {useAuth} from '../../auth'
import {STEPS} from '../core/_const'
import {Onboarding, Pricing} from '../../onboard'
import axios from 'axios'

const OnboardPage: React.FC = () => {
  const {newCancelToken, isCancel} = useCancelToken()
  const {currentUser} = useAuth()
  const [activeStep, setActiveStep] = useState<number>(STEPS.welcome)
  const [onboard, setOnboard] = useState<any>([])
  const [prepareData, setPrepareData] = useState<any>({})
  const SIGNATURE_ACCESS_KEY = process.env.REACT_APP_SIGNATURE_ACCESS_KEY

  useEffect(() => {
    const fetchData = async () => {
      try {
        axios.defaults.headers.common[`${SIGNATURE_ACCESS_KEY}`] =
          currentUser?.current_account?.encrypt_key
        const data = await OnboardService.get({cancelToken: newCancelToken()})
        setOnboard(data)
      } catch (error) {
        if (isCancel(error)) return
        console.error('Error fetching data:', error)
      }
    }

    fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.current_account?.encrypt_key])

  const sectionComponent = useMemo(() => {
    switch (activeStep) {
      case STEPS.welcome:
        return <Welcome setActiveStep={setActiveStep} />
      case STEPS.single_step:
        return (
          <Onboarding
            setActiveStep={setActiveStep}
            onboard={onboard}
            prepareData={prepareData}
            setPrepareData={setPrepareData}
          />
        )
      case STEPS.pricing:
        return (
          <Pricing
            setActiveStep={setActiveStep}
            onboard={onboard}
            prepareData={prepareData}
            setPrepareData={setPrepareData}
          />
        )
      default:
        return <Welcome setActiveStep={setActiveStep} />
    }
  }, [activeStep, onboard, prepareData])

  return (
    <div id='onboard' className='onboard'>
      <div className='onboard-logo'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/logos/big-shipbae-logo.png')}
          className='h-45px'
        />
      </div>
      <div className='container'>{sectionComponent}</div>
    </div>
  )
}

export {OnboardPage}
