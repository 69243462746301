import React from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {Button} from '../form/Button'

type Props = {
  handleClose?: any
}

const SpecialOfferModal: React.FC<Props> = ({handleClose}) => {
  const intl = useIntl()

  return (
    <Modal
      id='gori_special_offer_modal'
      tabIndex={-1}
      aria-hidden='true'
      centered
      dialogClassName='mw-650px h-auto'
      show={true}
      onHide={() => handleClose()}
    >
      <div className='modal-content'>
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}>
            {intl.formatMessage({id: 'PRICING_MODAL_TITLE'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='vh-75 scroll-y'>
          <div className='text-center'>
            <KTSVG
              path={`/media/gori/onboard/welcome/badge-1year-free.svg`}
              svgClassName='h-100 mh-100 my-10'
              small={false}
            />
          </div>
          <div className='fw-boldest fs-3 text-black lh-lg mx-10'>
            {intl.formatMessage({id: 'PRICING_MODAL_CONTENT'})}
          </div>
          <div className='fst-italic fs-6 text-black mx-10 my-5'>
            *{intl.formatMessage({id: 'PRICING_MODAL_FOOTNOTE'})}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='btn btn-primary'
            label={intl.formatMessage({id: 'CLOSE'})}
            event={() => handleClose()}
          />
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export {SpecialOfferModal}
