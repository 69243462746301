export const FAQ_ACCORDION_ITEMS = [
  {
    title: 'PRICING_FAQ_HEADING_1',
    content: 'PRICING_FAQ_CONTENT_1',
  },
  {
    title: 'PRICING_FAQ_HEADING_2',
    content: 'PRICING_FAQ_CONTENT_2',
    content2: 'PRICING_FAQ_CONTENT_2a',
    content3: 'PRICING_FAQ_CONTENT_2b',
    linkLabel: 'PRICING_FAQ_CONTENT_2_LINK_LABEL',
    link: 'PRICING_FAQ_CONTENT_2_LINK',
    linkLabel2: 'PRICING_FAQ_CONTENT_2_LINK_LABEL2',
    link2: 'PRICING_FAQ_CONTENT_2_LINK2',
    hasLink: true,
  },
]
