import React, {useState, useEffect, useRef, useCallback} from 'react'
import {toAbsoluteUrl} from '../../../../_gori/helpers'

export const RevealSlider: React.FC = () => {
  const [sliderPos, setSliderPos] = useState(0)
  const [revealSliderWidth, setRevealSliderWidth] = useState(0)
  const [isIntersecting, setIsIntersecting] = useState(false)

  const revealSliderRef = useRef<HTMLDivElement | null>(null)
  const animationRef = useRef<number | null>(null)

  const updateRevealSliderWidth = () => {
    if (revealSliderRef.current) {
      const width = revealSliderRef.current.offsetWidth
      setRevealSliderWidth(width)
    }
  }

  const sliderAnimation = useCallback(() => {
    let lastTime: number | null = null

    const animate = (time: number) => {
      if (!isIntersecting) {
        if (animationRef.current) {
          cancelAnimationFrame(animationRef.current)
        }
        return
      }

      if (lastTime === null) {
        lastTime = time
      }

      const deltaTime = time - lastTime
      lastTime = time

      setSliderPos((prevPos) => {
        if (prevPos >= 30) {
          if (animationRef.current) {
            cancelAnimationFrame(animationRef.current)
          }
          return prevPos
        }

        const speedPerSecond = 50
        const increment = (speedPerSecond / 1000) * deltaTime

        return Math.min(prevPos + increment, 30)
      })

      animationRef.current = requestAnimationFrame(animate)
    }

    animationRef.current = requestAnimationFrame(animate)
  }, [isIntersecting])

  useEffect(() => {
    updateRevealSliderWidth()
    window.addEventListener('resize', updateRevealSliderWidth)

    const node = revealSliderRef.current // Store the ref in a local variable

    const observerCallback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
          setIsIntersecting(true)
        } else {
          setIsIntersecting(false)
        }
      })
    }

    const observerOptions: IntersectionObserverInit = {
      threshold: [0, 0.5, 1],
    }

    const observer = new IntersectionObserver(observerCallback, observerOptions)

    if (node) {
      observer.observe(node)
    }

    return () => {
      window.removeEventListener('resize', updateRevealSliderWidth)

      if (node) {
        observer.unobserve(node)
      }

      observer.disconnect()

      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (isIntersecting) {
      sliderAnimation()
    } else {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current)
      }
    }

    // Clean up when isIntersecting changes
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current)
      }
    }
  }, [isIntersecting, sliderAnimation])

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setSliderPos(Number(newValue))

    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current)
    }
  }

  return (
    <div className='container overflow-hidden'>
      <div className='reveal-slider-buffer-container'>
        <div className='reveal-slider' ref={revealSliderRef}>
          <div
            className='img background-img'
            style={{
              backgroundImage: `url(${toAbsoluteUrl('/media/gori/landing/solution.webp')})`,
              backgroundSize: `${revealSliderWidth}px 100%`,
            }}
          ></div>

          <div
            className='img foreground-img'
            style={{
              backgroundImage: `url(${toAbsoluteUrl('/media/gori/landing/painpoint.webp')})`,
              backgroundSize: `${revealSliderWidth}px 100%`,
              width: `${sliderPos}%`,
            }}
          ></div>

          <div
            className='slider-button'
            style={{
              left: `calc(${sliderPos}% - 18px)`,
            }}
          ></div>

          <input
            type='range'
            min='1'
            max='100'
            value={sliderPos}
            onChange={handleSliderChange}
            className='slider'
            name='slider'
            id='slider'
          />
        </div>
      </div>
    </div>
  )
}

export default RevealSlider
