import {useFormik} from 'formik'
import {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useSearchParams} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import * as Yup from 'yup'
import {PasswordMeterComponent} from '../../../../_gori/assets/ts/components'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../_gori/hooks/UseYupValidate'
import {Button} from '../../../../_gori/partials/widgets'
import {AcceptTermCheckbox} from '../../../../_gori/partials/widgets/form/AcceptTermCheckbox'
import {InputPassword} from '../../../../_gori/partials/widgets/form/InputPassword'
import AuthService from '../core/_requests'
import {blockPopUp} from '../../../../_gori/helpers/BrowserHelpers'
import {toast} from 'react-toastify'
import {isFeatureEnabled} from '../../../../_gori/helpers'
import {FEATURES} from '../../../../_gori/constants/_features'

export function NewPassword() {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const [searchParams] = useSearchParams()
  const {authYup} = UseYupValidate()
  const token = searchParams.get('token')
  const [loading, setLoading] = useState<{first: boolean; after: boolean}>({
    first: true,
    after: false,
  })
  const [expired, setExpired] = useState<boolean>(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const newPasswordSchema = Yup.object().shape({
    password: authYup.password(),
    password_confirmation: authYup.passwordConfirmation(),
    accept_terms: authYup.acceptTerms,
  })

  const initialValues = {
    password: '',
    password_confirmation: '',
    accept_terms: false,
  }

  const formik = useFormik({
    initialValues,
    validationSchema: newPasswordSchema,
    onSubmit: async (values) => {
      setLoading((prev) => ({...prev, after: true}))

      const config = {cancelToken: newCancelToken()}
      try {
        if (!token) return
        await AuthService.newPassword(
          {
            ...values,
            accept_terms: values.accept_terms,
            token: token,
          },
          config
        )
        setHasErrors(false)
      } catch (error) {
        if (isCancel(error)) return
        setHasErrors(true)
      } finally {
        setLoading((prev) => ({...prev, after: false}))
      }
    },
  })

  const checkUserVerified = useCallback(async () => {
    if (!token) {
      setLoading((prev) => ({...prev, first: false}))
      return setHasErrors(true)
    }
    const config = {
      params: {
        confirmation_token: token,
        type: 'reset-password',
      },
    }

    try {
      setLoading((prev) => ({...prev, first: true}))
      await AuthService.verifyToken(config)
    } catch (error: any) {
      if (error?.response?.data?.error?.fields?.expired) {
        setExpired(true)
      }
      setHasErrors(true)
    } finally {
      setLoading((prev) => ({...prev, first: false}))
      PasswordMeterComponent.bootstrap()
    }
  }, [token])

  useEffect(() => {
    checkUserVerified()
  }, [checkUserVerified])

  return (
    <>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='w-lg-550px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          {loading.first ? (
            <div>
              <div className='text-center'>
                <span className='fs-1 col-6 placeholder placeholder-lg rounded-2 bg-secondary' />
                <span className='fs-1 col-10 mt-3 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
              <span className='btn col-12 mt-7 placeholder placeholder-lg rounded-2 bg-secondary' />
              <span className='btn col-12 mt-7 placeholder placeholder-lg rounded-2 bg-secondary' />
              <div className='d-flex justify-content-center'>
                <span className='btn col-4 mt-6 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
            </div>
          ) : (
            <div className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
              {token && hasErrors === undefined && (
                <>
                  <div className='mb-10 text-center'>
                    <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'NEW_PASSWORD'})}</h1>
                    <div className='text-gray-400 fw-bold fs-4'>
                      {intl.formatMessage({id: 'ALREADY_HAVE_RESET_YOUR_PASSWORD'})}{' '}
                      <Link to='/auth/login' className='link-primary fw-bolder'>
                        {intl.formatMessage({id: 'SIGN_IN_HERE'})}
                      </Link>
                    </div>
                  </div>
                  <InputPassword
                    className={'mb-10'}
                    label={intl.formatMessage({id: 'PASSWORD'})}
                    size={'lg'}
                    strengthMessage={intl.formatMessage({id: 'PASSWORD_STRENGTH_MESSAGE'})}
                    formik={formik}
                    name={'password'}
                    required
                  />
                  <InputPassword
                    className={'mb-10'}
                    label={intl.formatMessage({id: 'CONFIRM_PASSWORD'})}
                    size='lg'
                    openHighlight={false}
                    formik={formik}
                    name={'password_confirmation'}
                    required
                  />
                  {isFeatureEnabled(FEATURES.SERVICE_TERMS) && (
                    <AcceptTermCheckbox
                      className={'mb-10'}
                      labelCheck={intl.formatMessage({id: 'I_AGREE'})}
                      labelLink={intl.formatMessage({id: 'TERMS_AND_CONDITIONS'})}
                      formik={formik}
                      name={'accept_terms'}
                      links={[
                        {
                          label: intl.formatMessage({id: 'TERMS_AND_CONDITIONS'}),
                          onClick: () => {
                            if (blockPopUp('https://shipbae.com/service-terms')) {
                              toast.warning(intl.formatMessage({id: 'POP_UP_IS_BLOCKED'}))
                            }
                          },
                        },
                      ]}
                    />
                  )}
                  <div className='text-center'>
                    <Button
                      className='btn btn-lg btn-primary fw-bolder'
                      label={intl.formatMessage({id: 'SUBMIT'})}
                      loadingText={intl.formatMessage({id: 'SUBMIT'})}
                      loading={loading.after}
                      disabled={loading.after || !formik.isValid}
                      event={formik.submitForm}
                    />
                  </div>
                </>
              )}

              {hasErrors === false && (
                <>
                  <div className='mb-10 text-center'>
                    <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'NEW_PASSWORD'})}</h1>
                  </div>

                  <div className='mb-lg-10 alert bg-light-primary alert-dismissible'>
                    <div className='alert-text'>
                      {intl.formatMessage({
                        id: 'YOU_HAVE_SUCCESSFULLY_RESET_YOUR_PASSWORD',
                      })}
                    </div>
                  </div>

                  <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                    <Link to='/auth/login' className='link-primary fw-bolder'>
                      <button
                        type='submit'
                        id='gori_sign_in_submit'
                        className='btn btn-lg btn-primary fw-bolder me-4'
                      >
                        {intl.formatMessage({id: 'LOGIN'})}
                      </button>
                    </Link>
                  </div>
                </>
              )}

              {(!token || hasErrors === true) && (
                <>
                  <div className='mb-10 text-center'>
                    <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'NEW_PASSWORD'})}</h1>
                  </div>

                  <div className='mb-10 alert alert-danger alert-dismissible'>
                    <div className='alert-text'>
                      {expired
                        ? intl.formatMessage({id: 'INVALID_CONFIRMATION_TOKEN_EXPIRED'})
                        : intl.formatMessage({id: 'INVALID_CONFIRMATION_TOKEN'})}
                    </div>
                  </div>

                  <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                    <Link to='/auth/login' className='link-primary fw-bolder'>
                      <button
                        type='submit'
                        id='gori_sign_in_submit'
                        className='btn btn-lg btn-primary fw-bolder me-4'
                      >
                        {intl.formatMessage({id: 'LOGIN'})}
                      </button>
                    </Link>
                    <Link to='/auth/forgot-password' className='link-primary fw-bolder'>
                      <button
                        type='submit'
                        id='gori_sign_in_submit'
                        className='btn btn-lg btn-light-primary fw-bolder'
                      >
                        {intl.formatMessage({id: 'RESET_PASSWORD'})}
                      </button>
                    </Link>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </CSSTransition>
    </>
  )
}
